import Button from 'antd/es/button'
import Card from 'antd/es/card'
import Form from 'antd/es/form'
import Input from 'antd/es/input'
import message from 'antd/es/message'
import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'

import LockOutlined from '@ant-design/icons/LockOutlined'
import UserOutlined from '@ant-design/icons/UserOutlined'
import * as AuthServices from '../services/AuthServices'
import { PageCenter } from '../uikit/PageCenter'

const FormItem = Form.Item

export default function LoginPage() {
  const history = useHistory()
  const [authenticating, setAuthenticating] = useState(false)

  const onFinish = async (values: { email: string, password: string }) => {
    try {
      setAuthenticating(true)
      const authResult: AuthServices.LoginResult = await AuthServices.authenticate(values.email, values.password)
      setAuthenticating(false)

      if (!authResult.success) {
        message.error('Authentication failed')

        return
      }
      history.push('/')
    } catch (error) {
      message.error(`Error: ${(error as Error).message}`)
    }
  }

  return (
    <PageCenter>
      <Card title="Taskworld Private Server Administration">
        <Form onFinish={onFinish} className="login-form">
          <FormItem name="email" rules={[{ required: true, message: 'Please input your email!' }]}>
            <Input prefix={<UserOutlined style={{ color: 'rgba(0,0,0,.25)' }} />} placeholder="Email" />
          </FormItem>
          <FormItem name="password" rules={[{ required: true, message: 'Please input your Password!' }]}>
            <Input
              prefix={<LockOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
              type="password"
              placeholder="Password"
            />
          </FormItem>
          <FormItem>
            <Button
              type="primary"
              htmlType="submit"
              className="login-form-button"
              loading={authenticating}
            >
              Log in
            </Button>
          </FormItem>
        </Form>
      </Card>
    </PageCenter>
  )
}
