import * as PrivateServerTypes from './PrivateServerTypes'
export interface ILicenseInfo {
  expiryDate: Date
}
export interface IPrivateServerInfo {
  expiryDate: string
  userLimit: number
  activeUser: number
  activeGuest: number
  allUsersCanCreateWorkspace: boolean
  onlyWorkspaceAdminCanInvite: boolean
  superAdmins: string | null
  projectReportEnabled: boolean
  timeTrackingEnabled: boolean
  accountDeletionEnabled: boolean
  transferOwnershipEnabled: boolean
  forceProjectAdminTransfer: boolean
  userExportEnabled: boolean
}

export interface ILicenseUpdateRequest {
  workspaceCreator: PrivateServerTypes.WorkspaceCreator
  invite: PrivateServerTypes.Invitation
}
