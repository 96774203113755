export interface IPermissionSettings {
  display_nonmember_task: boolean
  project_modified: boolean
  display_public_project: boolean
}

export interface IRole {
  _id?: string
  name: string
  disabled_routes: string[]
  disabled_menus: string[]
  permission_settings: IPermissionSettings
}
