export interface IApiUpdateResult {
  readonly success: boolean
}

export interface IApiInviteUsersResult {
  readonly invitedUserEmails: readonly string[]
  readonly invalidEmails: readonly string[]
  readonly activeUserEmails: readonly string[]
  readonly awaitingConfirmationUsersEmails: readonly string[]
}

export interface IApiRoleConvertGuestToMembersUsersResult {
  readonly convertedIds: readonly string[]
  readonly notConvertedIds: readonly string[]
}

export interface IApiWorkspaceProjects {
  readonly _id: string,
  readonly title: string
}

export interface IApiWorkspaceProjectsResult {
  readonly projects: IApiWorkspaceProjects[]
}

export interface IWorkspaceProjects {
  readonly value: string,
  readonly label: string
}
