export enum WorkspaceCreator {
  EVERYONE = 'everyone',
  SPECIFIC_USERS = 'specific_users'
}

export enum Invitation {
  EVERYONE = 'everyone',
  WORKSPACE_ADMINS = 'workspace_admins'
}

