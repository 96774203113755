import React, { useMemo, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'

import Layout from 'antd/es/layout'
import Menu from 'antd/es/menu'
import _ from 'lodash'
import ErrorBoundary from './components/ErrorBoundary'
import { RoutingPath } from './routes'
import BookOutlined from '@ant-design/icons/lib/icons/BookOutlined'
import DesktopOutlined from '@ant-design/icons/lib/icons/DesktopOutlined'
import LogoutOutlined from '@ant-design/icons/lib/icons/LogoutOutlined'
import UserOutlined from '@ant-design/icons/lib/icons/UserOutlined'

export default function MainLayout(props: { readonly children: React.ReactNode }) {
  const location = useLocation()
  const [collapsed, setCollapsed] = useState(false)

  const menu = useMemo(() => {
    const renderMenuItem = (path: RoutingPath, name: string, Icon: React.ComponentType) => {
      return (
        <Menu.Item key={path}>
          <Link to={path}>
            <Icon />
            <span>{name}</span>
          </Link>
        </Menu.Item>
      )
    }

    return (
      <Menu theme="dark" selectedKeys={location.pathname.match(/\/[^\/]+/) || []} mode="inline">
        {renderMenuItem('/users', 'Users', UserOutlined)}
        {renderMenuItem('/workspaces', 'Workspaces', BookOutlined)}
        {renderMenuItem('/license', 'Private server info', DesktopOutlined)}
        <Menu.Divider />
        {renderMenuItem('/logout', 'Logout', LogoutOutlined)}
      </Menu>
    )
  }, [location.pathname])

  return (
    <Layout style={{ minHeight: '100vh' }}>
      <Layout.Sider collapsible collapsed={collapsed} onCollapse={setCollapsed}>
        <div className="logo" />
        {menu}
      </Layout.Sider>
      <Layout>
        <Layout.Content className="main-layout">
          <ErrorBoundary>{props.children}</ErrorBoundary>
        </Layout.Content>
        <Layout.Footer style={{ textAlign: 'center' }}>Taskworld Inc.</Layout.Footer>
      </Layout>
    </Layout>
  )
}
