import DownOutlined from '@ant-design/icons/DownOutlined';
import UserOutlined from '@ant-design/icons/UserOutlined';
import FilterOutlined from '@ant-design/icons/FilterOutlined';
import { Button, Dropdown, Menu } from 'antd';
import _ from 'lodash'
import React from 'react'
import UsersTable from './UsersTable'
import './UserPage.css'
import { useUrlState } from 'with-url-state';
import { useHistory } from 'react-router-dom';

const roles = ['ALL', 'ADMIN', 'MEMBER', 'GUEST']

export default function UsersPage() {
  const history = useHistory()
  const [urlState, setUrlState] = useUrlState<any>({}, { history })

  const handleRoleMenuClick = ({ key }: any) => {
    setUrlState({role: key.toUpperCase() })
  }

  const menu = (
    <Menu onClick={handleRoleMenuClick}>
      {roles.map((role) => <Menu.Item key={role} icon={<UserOutlined />}>
        {_.capitalize(role)}
      </Menu.Item>
      )}
    </Menu>
  );

  return (
      <UsersTable
        header={() => (
          <div className="filter-bar">
            <FilterOutlined className="filter-icon" />
            <span className="filter-label">Role:</span>
            <Dropdown overlay={menu}>
              <Button>
                {urlState.role ? urlState.role : 'Select role'} <DownOutlined />
              </Button>
            </Dropdown>
          </div>
        )}
        includeCountedAs
        includeNumberOfWorkspaces
        role={urlState.role ? urlState.role : undefined}
      />
  )
}
