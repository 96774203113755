import React, { useState } from 'react'
import { Caption } from './Caption'
import Col from 'antd/es/col'
import Switch from 'antd/es/switch'
import { FieldRow } from './FieldRow'
import message from 'antd/es/message'

export function Toggle(props: {
  title: string
  caption?: React.ReactNode
  checked: boolean
  onChange: (checked: boolean) => Promise<void>
}) {
  const [optimisticChecked, setOptimisticChecked] = useState(props.checked)
  const [loading, setLoading] = useState(false)

  return (
    <FieldRow>
      <Col span={8}>{props.title}:</Col>
      <Col style={{ fontWeight: 'bold' }} span={16}>
        <Switch
          checked={optimisticChecked}
          onChange={async (value) => {
            setOptimisticChecked(value)
            setLoading(true)

            try {
              await props.onChange(value)
            } catch (error) {
              setOptimisticChecked(props.checked)
              message.error(String(error))
            } finally {
              setLoading(false)
            }
          }}
          loading={loading}
        />
        {props.caption && <Caption>{props.caption}</Caption>}
      </Col>
    </FieldRow>
  )
}
