import _ from 'lodash'
import qs from 'query-string'
import { useEffect, useMemo, useState } from 'react'
import useSWR from 'swr'
import { message } from 'antd'
import * as AuthServices from '../services/AuthServices'
import { IApiUpdateResult, IUserWrapper } from '../types'
import { forceFetchingWorkspaces } from './useWorkspaces'

const fetchers: (() => Promise<boolean>)[] = []
export async function forceFetchingUsers(): Promise<void> {
  await Promise.all(
    fetchers.map(revalidate => revalidate())
  )
}

export default function useUsers(options: Partial<{
  readonly userId: string
  readonly workspaceId: string

  readonly email: string
  readonly first_name: string
  readonly last_name: string
  readonly name: string
  readonly job_title: string
  readonly status: readonly number[]
  readonly role: string

  readonly pageIndex: number
  readonly pageChunk: number

  readonly sortColumn: string
  readonly sortOrder: 'ascend' | 'descend' | null
  readonly mfaStatus: boolean
}> = {}) {
  const { data, error, revalidate } = useSWR(() => {
    const query = _.omitBy(options, (value) => _.isFunction(value))
    return 'users?' + qs.stringify(query, { arrayFormat: 'comma', skipEmptyString: true })
  }, (url) => {
    const client = AuthServices.getAuthenticatedApiConnector()

    return client.get<{ userWrappers: readonly IUserWrapper[], totalMatchingUserCount: number, allMatchingUserIds: string[] }>(url) || []
  },
  {
    revalidateOnFocus: false
  })

  useEffect(() => {
    fetchers.push(revalidate)

    return () => {
      const index = fetchers.indexOf(revalidate)
      if (index >= 0) {
        fetchers.splice(index, 1)
      }
    }
  }, [options.pageIndex])

  // Do not replace this with `data === undefined` or `isRevalidating` as the update operations start before re-fetching data
  const [loading, setLoading] = useState(false)

  const output = useMemo(() => ({
    loading: data === undefined ? true : loading,
    userWrappers: data?.userWrappers || [],
    totalMatchingUserCount: data?.totalMatchingUserCount || 0,
    allMatchingUserIds: data?.allMatchingUserIds || [],
    updateAdminVpc: async (userId: string, isVpcAdmin: boolean): Promise<void> => {
      setLoading(true)

      const client = AuthServices.getAuthenticatedApiConnector()
      try {
        await client.post<IApiUpdateResult>('users/set-admin', {
          userId,
          isVpcAdmin
        })
        
        await revalidate()

        await forceFetchingWorkspaces()
      } finally {
        setLoading(false)
      }
    },
    updateCanCreateWorkspace: async (userId: string, canCreateWorkspace: boolean): Promise<void> => {
      setLoading(true)

      const client = AuthServices.getAuthenticatedApiConnector()
      await client.post('users/set-create-ws', {
        userId,
        canCreateWorkspace
      })

      await revalidate()

      setLoading(false)
    },
    updateResetPasswordLink: async (userId: string): Promise<string> => {
      const client = AuthServices.getAuthenticatedApiConnector()
      return client.post<string>('users/generate-reset-password-link', {
        userId
      })
    },
    removeUserAccount: async (userId: string): Promise<void> => {
      setLoading(true)

      const client = AuthServices.getAuthenticatedApiConnector()
      await client.delete('users/remove', {
        data: { userId }
      })

      await revalidate()

      setLoading(false)
    },

    updateUserWorkspaceRole: async (userId: string, workspaceId: string, role: 'ADMIN' | 'MEMBER') => {
      setLoading(true)

      const client = AuthServices.getAuthenticatedApiConnector()
      await client.post('users/set-role-ws', {
        userId,
        workspaceId,
        role,
      })

      await revalidate()

      setLoading(false)
    },

    disableMFA: async (userId: string, userName: string) => {
      const client = AuthServices.getAuthenticatedApiConnector()
      const err = await client.post('users/disable-mfa', { userId })
      .then(revalidate)
      .catch(() => 'Fail to disable mfa')

      if (err === 'Fail to disable mfa') {
        const content = 'System Error! Try again later'
        const className = 'mfa-message mfa-error'
        message.open({ content, duration: 1, type: 'error', className })
      } else {
        const content = `You have disabled ${userName} MFA`
        const className = 'mfa-message mfa-success'
        message.open({ content , duration: 1, type: 'success', className })
      }
      
    },
  }), [data, loading])

  if (error) {
    throw error
  }

  return output
}
