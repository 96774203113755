import axios, { AxiosRequestConfig } from 'axios'
import _ from 'lodash'


const localVpcBackendUrl = `http://localhost:${process.env.VPC_ADMIN_PORT || 9082}`
// eslint-disable-next-line
// @ts-ignore
const rootPath = process.env.NODE_ENV === 'production' ? window.API_HOST || window.location.origin : localVpcBackendUrl

export function getApiConnector() {
  return new ApiConnector()
}

export function getAuthenticatedApiConnectorFromAccessToken(accessToken: string) {
  return new AuthenticatedApiConnector(accessToken)
}

interface IApiConnector {
  get: <T>(path: string, config: AxiosRequestConfig | undefined) => Promise<T>
  post: <T>(path: string, body: any) => Promise<T>
}

export class ApiConnector implements IApiConnector {
  async get<T>(path: string, config?: AxiosRequestConfig) {
    const response = await axios.get(`${rootPath}/${path}`, config)
    return response.data as T
  }
  async post<T>(path: string, body: any) {
    const response = await axios.post(`${rootPath}/${path}`, body)
    return response.data as T
  }
}

export class AuthenticatedApiConnector implements IApiConnector {
  accessToken: string
  constructor(accessToken: string) {
    this.accessToken = accessToken
  }
  async get<T>(path: string, config?: AxiosRequestConfig) {
    const response = await axios.get(`${rootPath}/${path}`, {
      headers: {
        Authorization: `Bearer ${this.accessToken}`
      },
      ...config
    })
    return response.data as T
  }
  async post<T>(path: string, body: any) {
    const response = await axios.post(`${rootPath}/${path}`, body, {
      headers: {
        Authorization: `Bearer ${this.accessToken}`
      }
    })
    return response.data as T
  }
  async delete<T>(path: string, config?: AxiosRequestConfig) {
    const response = await axios.delete(`${rootPath}/${path}`, {
      headers: {
        Authorization: `Bearer ${this.accessToken}`
      },
      ...config
    })
    return response.data as T
  }
}

export function getApiErrorMessage(errorObject: any): string {
  return _.get(errorObject, 'response.data.message', 'Unexpected error') as string
}

