export const taskworldColors = {
  primary: '#15A4AD',
  primaryHover: '#259295',
  danger: '#D5493B',
  tableRowSelectedBg: 'white',
  tableRowSelectedBgHover: '#FAFAFA',
  gray500: '#696F7A',
  btnDisabled: 'rgba(255, 255, 255, 1)',
  btnTextDisabled: 'rgba(0, 0, 0, 0.25)'

}
