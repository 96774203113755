import { IApiUpdateResult } from '../types'
import * as AuthServices from './AuthServices'

export async function triggerAuditDownload(email: string, dateRange: [Date, Date]): Promise<boolean> {
  const client = AuthServices.getAuthenticatedApiConnector()
  if (!client) {
    throw new Error('Not authorized')
  }
  const result = await client.post<IApiUpdateResult>('audits', {
    email,
    start_date: dateRange[0],
    end_date: dateRange[1],
  })

  return !!result.success
}

export async function triggerMemberDownload(email: string): Promise<boolean> {
  const client = AuthServices.getAuthenticatedApiConnector()
  if (!client) {
    throw new Error('Not authorized')
  }
  const result = await client.post<IApiUpdateResult>('member-reports', {
    email
  })

  return !!result.success
}

export async function triggerUserDownload(email: string): Promise<boolean> {
  const client = AuthServices.getAuthenticatedApiConnector()
  if (!client) {
    throw new Error('Not authorized')
  }
  const result = await client.post<IApiUpdateResult>('user-reports', {
    email
  })

  return !!result.success
}
