import UserAddOutlined from '@ant-design/icons/UserAddOutlined'
import ExclamationCircleOutlined from '@ant-design/icons/ExclamationCircleOutlined'
import Button from 'antd/es/button'
import { PageHeader } from '@ant-design/pro-layout'
import _ from 'lodash'
import React, { useState } from 'react'
import { RouteComponentProps, useHistory } from 'react-router-dom'
import InvitationModal from '../components/InvitationModal'
import UserInWorkspaceExportButton from '../components/UserInWorkspaceExportButton'
import useWorkspaces from '../hooks/useWorkspaces'
import { LoadingStatePage } from '../uikit'
import UsersTable from './UsersTable'
import { ConfigProvider, Modal, Space } from 'antd'
import { taskworldColors } from '../colors'
import { IWorkspaceWrapper } from '../types'
import { UserWorkspaceDisplayStatus, getUserWorkspaceDisplayStatus } from '../rules/UserRules'
import { IUserWrapper } from '../types'
import { isCurrentUserVPCAdmin } from '../services/AuthServices'
import MembersToGuestsWorkspaceModal from './MembersToGuestsWorkspaceModal'
import useProjects from '../hooks/useProjects'

export default function WorkspaceDetailPage(props: RouteComponentProps<{ readonly workspaceId: string }>) {
  const history = useHistory()

  const workspaceId = props.match.params.workspaceId
  const workspaceWrapperCache = props.location.state?.workspaceWrapper as IWorkspaceWrapper | undefined
  const showInviteUserBtn = isCurrentUserVPCAdmin()

  const projects = useProjects(workspaceId)
  const { workspaceWrappers, bulkRemoveUsersFromWorkspace, convertGuestsToMembers, convertMembersToGuests } = useWorkspaces({
    workspaceId,
    shouldFetchData: !workspaceWrapperCache
  })
  const workspaceWrapper = workspaceWrapperCache
    ? workspaceWrapperCache
    : workspaceWrappers?.find((workspaceWrapper) => workspaceWrapper.workspace._id === workspaceId)

  const [invitationModalVisible, setInvitationModalVisible] = useState(false)
  const [selectedRowKeys, setSelectedRowKeys] = useState<string[]>([])

  const hasSelected = selectedRowKeys.length > 0

  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    setSelectedRowKeys(newSelectedRowKeys as string[])
  }

  if (workspaceWrapper === undefined) {
    return <LoadingStatePage />
  }

  const renderTableHeader = (allMatchingUserIds: string[]) => (
    <Space direction="horizontal" size={10}>
      <span>{`${selectedRowKeys.length} users selected`}</span>
      <div style={{ width: '1px', height: '32px', background: '#D9D9D9' }} />
      <Button
        onClick={() => {
          setSelectedRowKeys((prev) => [
            ...prev,
            ...allMatchingUserIds.filter((userId) => {
              const userStatus = getUserWorkspaceDisplayStatus(
                { _id: userId },
                workspaceWrapper?.workspace.member_profiles ?? []
              )

              return !prev.includes(userId) && userStatus !== UserWorkspaceDisplayStatus.REMOVED
            })
          ])
        }}
        disabled={selectedRowKeys.length === allMatchingUserIds.length}
      >
        Select all
      </Button>
      <Button
        onClick={() => {
          setSelectedRowKeys([])
        }}
        disabled={selectedRowKeys.length === 0}
      >
        Deselect all
      </Button>
      <Button
        disabled={!hasSelected}
        onClick={() => {
          Modal.confirm({
            onOk: async () => {
              await convertGuestsToMembers(selectedRowKeys, workspaceId).then(() => setSelectedRowKeys([]))
            },
            centered: true,
            title: 'Convert Guest to Member?',
            okType: 'primary',
            okButtonProps: {
              style: { backgroundColor: taskworldColors.primary }
            },
            okText: 'Convert to Member',
            cancelText: 'No',
            icon: <ExclamationCircleOutlined />,
            content: (
              <>
                {selectedRowKeys.length} users will be converted to members and will consume your licenses; admins and pending guests stay unaffected.
                <div>Do you want to proceed this action ?</div>
              </>
            )
          })
        }}
      >
        Convert Guest to Member
      </Button>
      <Button
        disabled={!hasSelected}        
        onClick={MembersToGuestsWorkspaceModal({
          selectedRowKeys, 
          setSelectedRowKeys, 
          convertMembersToGuests, 
          workspaceId,
          projects
        })}
      >
        Convert Member to Guest
      </Button>
      <Button
        type="primary"
        danger
        disabled={!hasSelected}
        onClick={() => {
          Modal.confirm({
            onOk: async () => {
              await bulkRemoveUsersFromWorkspace(selectedRowKeys, workspaceId).then(() => setSelectedRowKeys([]))
            },
            centered: true,
            title: 'Are you sure delete users?',
            okType: 'primary',
            okButtonProps: {
              danger: true,
              style: { backgroundColor: taskworldColors.danger }
            },
            okText: 'Delete users',
            cancelText: 'No',
            icon: <ExclamationCircleOutlined />,
            content: `${selectedRowKeys.length} of users will be deleted from this workspace after this action`
          })
        }}
      >
        Remove from workspace
      </Button>
    </Space>
  )

  return (
    <Space direction="vertical" size="middle" style={{ display: 'flex' }}>
      <PageHeader
        title={`Workspace: ${workspaceWrapper.workspace.display_name || workspaceWrapper.workspace.name}`}
        onBack={() => history.goBack()}
        extra={
          <React.Fragment>
            {workspaceWrapper.status !== 'pending_delete' && showInviteUserBtn && (
              <Button
                type="primary"
                icon={<UserAddOutlined />}
                onClick={() => {
                  setInvitationModalVisible(true)
                }}
              >
                Invite users
              </Button>
            )}
            <UserInWorkspaceExportButton workspace={workspaceWrapper.workspace} />
          </React.Fragment>
        }
      />
      <ConfigProvider
        theme={{
          token: {
            colorPrimaryBg: taskworldColors.tableRowSelectedBg,
            colorPrimaryBgHover: taskworldColors.tableRowSelectedBgHover
          }
        }}
      >
        <UsersTable
          header={renderTableHeader}
          workspaceWrapper={workspaceWrapper}
          verticalScrollHeight={200}
          rowSelection={{
            selectedRowKeys,
            onChange: onSelectChange,
            preserveSelectedRowKeys: true,
            getCheckboxProps: (record: IUserWrapper) => {
              const userStatus = getUserWorkspaceDisplayStatus(
                record.user,
                workspaceWrapper?.workspace.member_profiles ?? []
              )

              return {
                disabled: userStatus === UserWorkspaceDisplayStatus.REMOVED
              }
            }
          }}
        />
      </ConfigProvider>
      {invitationModalVisible && (
        <InvitationModal
          workspaceWrapper={workspaceWrapper}
          onClose={() => {
            setInvitationModalVisible(false)
          }}
        />
      )}
    </Space>
  )
}
