import Modal from 'antd/es/modal'
import React, { useState } from 'react'
import useWorkspaces from '../hooks/useWorkspaces'
import { taskworldColors } from '../colors'
import { getApiErrorMessage } from '../apiConnector'

export default function UserInWorkspaceRemovalModal(props: {
  readonly userId: string
  readonly workspaceIds: string[]
  readonly onClose: () => void
  readonly onRemoveSuccuss: () => void
}) {
  const [confirmLoading, setConfirmLoading] = useState(false);
  const { removeUserFromWorkspaces } = useWorkspaces({
    userId: props.userId,
    shouldFetchData: false
  })

  const onConfirmButtonClick = async () => {
    try {
      setConfirmLoading(true)
      await removeUserFromWorkspaces(props.userId, props.workspaceIds)
      props.onRemoveSuccuss()
      Modal.success({
        content: 'Workspaces have been removed from user successfully',
        centered: true,
        okButtonProps: {
          style: { backgroundColor: taskworldColors.primary }
        }
      })
    } catch (error) {
      Modal.error({
        title: 'Error removing workspaces from this user',
        content: getApiErrorMessage(error),
        centered: true,
        okText: 'Got it',
        okButtonProps: {
          style: { backgroundColor: taskworldColors.primary }
        }
      })
    }

    setConfirmLoading(false)
  }

  return (
    <div>
      <Modal
        visible
        title="Confirm member removal"
        okText="Confirm"
        cancelText="Cancel"
        centered
        onOk={onConfirmButtonClick}
        onCancel={props.onClose}
        confirmLoading={confirmLoading}
        okButtonProps={{
          danger: true,
          style: { backgroundColor: taskworldColors.danger }
        }}
      >
        <p>
          Upon confirmation, the user will no longer be able to access this workspace. Tasks created by, assigned to, or
          followed by this user will NOT be deleted.
        </p>
      </Modal>
    </div>
  )
}
