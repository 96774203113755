import qs from 'query-string'
import { useEffect, useMemo, useState } from 'react'
import useSWR from 'swr'
import { IWorkspaceWrapper } from '../types'
import * as AuthServices from '../services/AuthServices'
import { forceFetchingUsers } from './useUsers'
import { Modal } from 'antd'
import { taskworldColors } from '../colors'
import { getApiErrorMessage } from '../apiConnector'

const fetchers: (() =>  Promise<boolean>)[] = []
export async function forceFetchingWorkspaces(): Promise<void> {
  await Promise.all(
    fetchers.map(revalidate => revalidate())
  )
}

export default function useWorkspaces(options: Partial<{
  readonly workspaceId: string
  readonly userId: string

  readonly display_name: string
  readonly status: 'active' | 'pending_delete'

  readonly pageIndex: number
  readonly pageChunk: number

  readonly sortColumn: string
  readonly sortOrder: 'ascend' | 'descend' | null

  shouldFetchData: boolean
}> = {},
) {
  const { data, error, revalidate } = useSWR((options.shouldFetchData ?? true) ? () => {
    const query = qs.stringify(options, { arrayFormat: 'comma', skipEmptyString: true })

    return 'workspaces?' + query
  } : null, async (url) => {
    const client = AuthServices.getAuthenticatedApiConnector()

    return client.get<{ readonly workspaceWrappers: readonly IWorkspaceWrapper[], allMatchingWorkspaceIds: readonly string[], readonly totalMatchingWorkspaceCount: number }>(url) || []
  },
  {
    revalidateOnFocus: false
  })

  useEffect(() => {
    fetchers.push(revalidate)

    return () => {
      const index = fetchers.indexOf(revalidate)
      if (index >= 0) {
        fetchers.splice(index, 1)
      }
    }
  }, [options.workspaceId])

  // Do not replace this with `data === undefined` or `isRevalidating` as the update operations start before re-fetching data
  const [loading, setLoading] = useState(false)

  const workspaceWrappers = useMemo(() => data?.workspaceWrappers || [], [data])

  if (error) {
    throw error
  }

  return {
    workspaceWrappers,
    allMatchingWorkspaceIds: data?.allMatchingWorkspaceIds,
    loading: data === undefined ? true : loading,
    totalMatchingWorkspaceCount: data?.totalMatchingWorkspaceCount || 0,
    inviteUsersToWorkspace: async ({
      emails,
      role,
      dryRun,
      workspaceId
    }: {
      readonly emails: readonly string[]
      readonly role: 'member' | 'admin'
      readonly dryRun: boolean
      readonly workspaceId: string
    }) => {
      setLoading(true)

      const client = AuthServices.getAuthenticatedApiConnector()
      const result = await client.post<{
        readonly invitedUserEmails: readonly string[]
        readonly invalidEmails: readonly string[]
        readonly activeUserEmails: readonly string[]
        readonly awaitingConfirmationUsersEmails: readonly string[]
      }>(`workspaces/${workspaceId}/invite-users`, {
        emails,
        role,
        dryRun
      })

      await Promise.all([
        forceFetchingUsers(),
        forceFetchingWorkspaces(),
      ])

      setLoading(false)

      return result
    },
    removeUserFromWorkspaces: async (userId: string, workspaceIds: string[]): Promise<void> => {
      setLoading(true)

      const client = AuthServices.getAuthenticatedApiConnector()

      await client.post(`workspaces/user/${userId}/suspend-user`, {
        workspaceIds
      })

      await Promise.all([
        forceFetchingUsers(),
        forceFetchingWorkspaces(),
      ])

      setLoading(false)
    },
    bulkRemoveUsersFromWorkspace: async (userIds: string[], workspaceId: string): Promise<void> => {
      setLoading(true)
      try {
        const client = AuthServices.getAuthenticatedApiConnector()
        await client.post(`workspaces/${workspaceId}/suspend-users`, {
          userIds
        })

        await Promise.all([forceFetchingUsers(), forceFetchingWorkspaces()])
        Modal.success({
          content: 'Users have been removed successfully',
          centered: true,
          okButtonProps: {
            style: { backgroundColor: taskworldColors.primary }
          }
        })
      } catch (error) {
        Modal.error({
          title: 'Error removing users from this workspace',
          content: getApiErrorMessage(error),
          centered: true,
          okText: 'Got it',
          okButtonProps: {
            style: { backgroundColor: taskworldColors.primary }
          }
        })
      }

      setLoading(false)
    },
    convertGuestsToMembers: async (userIds: string[], workspaceId: string): Promise<void> => {
      setLoading(true)
      try {
        const client = AuthServices.getAuthenticatedApiConnector()
        const result = await client.post<{
          readonly convertedIds: readonly string[]
          readonly notConvertedIds: readonly string[]
        }>(`workspaces/${workspaceId}/user/convert-guests-to-members`, {
          userIds
        })

        await Promise.all([forceFetchingUsers(), forceFetchingWorkspaces()])
        Modal.success({
          content: `${result.convertedIds.length} guests have been converted to members successfully`,
          centered: true,
          width: 450,
          okText: 'Done',
          okButtonProps: {
            style: { backgroundColor: taskworldColors.primary }
          }
        })
      } catch (error) {
        Modal.error({
          title: 'Error converting guest to member.',
          content: getApiErrorMessage(error),
          centered: true,
          okText: 'Got it',
          okButtonProps: {
            style: { backgroundColor: taskworldColors.primary }
          }
        })
      }

      setLoading(false)
    },
    convertMembersToGuests: async (userIds: string[], workspaceId: string, projectId: string | undefined): Promise<void> => {
      setLoading(true)
      try {
        const client = AuthServices.getAuthenticatedApiConnector()
        const result = await client.post<string[]>(`workspaces/${workspaceId}/user/convert-members-to-guests`, {
          userIds,
          projectId
        })

        await Promise.all([forceFetchingUsers(), forceFetchingWorkspaces()])
        Modal.success({
          content: `${result.length} members have been converted to guests successfully`,
          okButtonProps: {
            style: { backgroundColor: taskworldColors.primary }
          },
          width: 450,
        })
      } catch (error) {
        Modal.error({
          title: 'Error converting member to guest.',
          content: getApiErrorMessage(error),
          centered: true,
          okText: 'Got it',
          okButtonProps: {
            style: { backgroundColor: taskworldColors.primary }
          }
        })
      }

      setLoading(false)
    },
    deleteWorkspace: async (workspaceId: string) => {
      setLoading(true)

      const client = AuthServices.getAuthenticatedApiConnector()
      await client.post('workspace/delete', { workspaceId })

      await forceFetchingWorkspaces()

      setLoading(false)
    },
    restoreWorkspace: async (workspaceId: string) => {
      setLoading(true)

      const client = AuthServices.getAuthenticatedApiConnector()
      await client.post('workspace/restore', { workspaceId })

      await forceFetchingWorkspaces()

      setLoading(false)
    },
  }
}
