import Modal from 'antd/es/modal'
import _ from 'lodash'
import React, { useState } from 'react'
import { IWorkspace } from '../types'
import * as ApiConnector from '../apiConnector'

type Props = {
  readonly workspace: Pick<IWorkspace, 'display_name'>
  readonly onConfirm: () => Promise<void>
  readonly onClose: () => void
}

export default function WorkspaceDeletionCancellation(props: Props) {
  const [loading, setLoading] = useState(false)

  const onConfirmButtonClick = async () => {
    try {
      setLoading(true)
      await props.onConfirm()
      props.onClose()
    } catch (error) {
      Modal.error({
        title: 'Restore Workspace Failed!',
        content: ApiConnector.getApiErrorMessage(error),
        centered: true
      })
      setLoading(false)
    }
  }

  return (
    <Modal
      visible
      title="Cancel deleting workspace"
      okText="Cancel deleting"
      cancelText="Keep deleting"
      centered
      confirmLoading={loading}
      closable={!loading}
      maskClosable={!loading}
      okButtonProps={{ disabled: loading }}
      cancelButtonProps={{ disabled: loading }}
      onOk={onConfirmButtonClick}
      onCancel={props.onClose}
    >
      Do you want to cancel deleting workspace <b>{props.workspace.display_name || props.workspace.name}</b>?
      If yes, the countdown will be stopped.
    </Modal>
  )
}
