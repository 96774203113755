function isOnline() {
    if (typeof navigator.onLine !== 'undefined') {
        return navigator.onLine;
    }
    // always assume it's online
    return true;
}
function isDocumentVisible() {
    if (typeof document !== 'undefined' &&
        typeof document.visibilityState !== 'undefined') {
        return document.visibilityState !== 'hidden';
    }
    // always assume it's visible
    return true;
}
var fetcher = function (url) { return fetch(url).then(function (res) { return res.json(); }); };
export default {
    isOnline: isOnline,
    isDocumentVisible: isDocumentVisible,
    fetcher: fetcher
};
