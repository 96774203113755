export enum FetchState {
  NotLoaded = 'NotLoaded',
  Loading = 'Loading',
  Loaded = 'Loaded',
  Failed = 'Failed'
}

export const isNotLoaded = (state: FetchState): boolean => {
  return state === FetchState.NotLoaded
}

export const isLoading = (state: FetchState): boolean => {
  return state === FetchState.Loading || state === FetchState.NotLoaded
}

export const isLoaded = (state: FetchState): boolean => {
  return state === FetchState.Loaded
}

export const isError = (state: FetchState): boolean => {
  return state === FetchState.Failed
}
