export enum USER_COUNTED_AS {
  USER = 'User',
  GUEST = 'Guest',
  NOT_COUNTED = 'Not Counted'
}

export interface ICompactUser {
  _id: string
  photo: string
  is_system_user: boolean
  first_name: string
  last_name: string
  full_name: string
  display_name: string
  email: string
  last_login: Date
  created: Date
  updated: Date
  time_zone: string
  language: string
  department: string
  job_title: string
  phone: string
  is_active: boolean
  is_pending: boolean
  display_picture: string
  last_workspace: string
  address: string
  date_of_birth: string
  last_remind_web_push: Date
  can_reset_email: boolean
  is_vpc_admin: boolean
  is_login_by_adfs: boolean
  status?: number
  can_change_password: boolean
  can_create_workspace?: boolean
}

export interface IUserWrapper {
  readonly user: ICompactUser
  readonly countedAs: USER_COUNTED_AS
  readonly numberOfWorkspaces: number
  readonly numberOfWorkspacesAsMember: number
  readonly numberOfWorkspacesAsAdmin: number
  readonly numberOfWorkspacesAsGuest: number
  readonly roles: {
    readonly [workspaceId: string]: string
  }
  readonly invitedBy?: string
  readonly disableMFA?: () => void | Promise<void>
  mfa: boolean | undefined
}
