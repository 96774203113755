import { AxiosError } from 'axios'
import { cache } from 'swr'
import React from 'react'
import { getApiErrorMessage } from '../apiConnector'
import { RedirectInternal } from '../routes'

export default class ErrorBoundary extends React.Component<{}, { error: AxiosError | undefined }> {
  constructor(props: {}) {
    super(props)
    this.state = { error: undefined }
  }

  static getDerivedStateFromError(error: AxiosError) {
    // Update state so the next render will show the fallback UI.
    return { error }
  }

  render() {
    if (this.state.error && this.state.error.response?.status === 401) {
      // See: https://enterprise.taskworld.com/taskworld.com/#/home?show=/task/171762
      cache.clear()
      return <RedirectInternal to="/login" />
    }

    if (!!this.state.error) {
      // You can render any custom fallback UI
      return <h1>{getApiErrorMessage(this.state.error)}</h1>
    }

    return this.props.children
  }
}
