import Button from 'antd/es/button'
import Col from 'antd/es/col'
import Input from 'antd/es/input'
import Modal from 'antd/es/modal'
import DatePicker from 'antd/es/date-picker';
import React, { useState } from 'react'

import DownloadOutlined from '@ant-design/icons/lib/icons/DownloadOutlined'
import WorkspaceExportButton from '../../components/WorkspaceExportButton'
import { FieldRow } from '../../uikit'
import { DownloadingReport } from './LicensePage'

export interface IDataTabProps {
  readonly defaultEmail: string
  readonly downloadingReport: DownloadingReport
  readonly onDownloadAuditReport: (targetEmail: string, range: [Date, Date]) => Promise<void>
  readonly onDownloadMemberReport: (targetEmail: string) => void
  readonly onDownloadUserReport: (targetEmail: string) => void
}

const { RangePicker } = DatePicker

export default function DataTab(props: IDataTabProps) {
  const [email, setEmail] = useState(props.defaultEmail)
  const [selectedReport, setSelectedReport] = useState<'audit' | 'member' | 'user' | null>(null)
  const [selectedRange, setSelectedRange] = useState<[Date, Date] | null>(null)
  const [loading, setLoading] = useState<boolean>(false)

  const onDownloadMembers = () => {
    props.onDownloadMemberReport(email)
  }

  const onDownloadUsers = () => {
    props.onDownloadUserReport(email)
  }

  const onEmailChanges = (e: React.FormEvent<HTMLInputElement>) => {
    setEmail(e.currentTarget.value)
  }

  const shouldDisableButton = (downloadingReport: DownloadingReport) => {
    return props.downloadingReport !== null && props.downloadingReport !== downloadingReport
  }

  const shouldLoadingButton = (downloadingReport: DownloadingReport) => {
    return props.downloadingReport === downloadingReport
  }

  const handleClose = () => {
    setSelectedReport(null)
    setSelectedRange(null)
  }

  const handleOk = async () => {
    if (!selectedRange) {
      return
    }
    setLoading(true)
    switch (selectedReport) {
      case 'audit': {
        await props.onDownloadAuditReport(email, selectedRange)
        break
      }
      case 'member': {
        props.onDownloadMemberReport(email)
        break
      }
      case 'user': {
        props.onDownloadUserReport(email)
        break
      }
      default:
        break
    }
    setLoading(false)
  }

  const handleSelectedDate = (v: any) => {
    // returns moment objects
    const [start, end] = v
    setSelectedRange([start.toDate() as Date, end.toDate() as Date])
  }

  return (
    <div>
      <FieldRow>
        <Col span={8}>Send audits to email:</Col>
        <Col span={8}>
          <Input type="text" value={email} onChange={onEmailChanges} />
        </Col>
      </FieldRow>
      <FieldRow>
        <Button
          htmlType="button"
          type="primary"
          icon={<DownloadOutlined />}
          onClick={() => setSelectedReport('audit')}
          disabled={shouldDisableButton('audit')}
          loading={shouldLoadingButton('audit')}
        >
          Download Audits
        </Button>
      </FieldRow>
      <FieldRow>
        <Button
          htmlType="button"
          type="primary"
          icon={<DownloadOutlined />}
          onClick={onDownloadMembers}
          disabled={shouldDisableButton('member')}
          loading={shouldLoadingButton('member')}
        >
          Download Member report
        </Button>
      </FieldRow>
      <FieldRow>
        <Button
          htmlType="button"
          type="primary"
          icon={<DownloadOutlined />}
          onClick={onDownloadUsers}
          disabled={shouldDisableButton('user')}
          loading={shouldLoadingButton('user')}
        >
          Download User report
        </Button>
      </FieldRow>
      <FieldRow>
        <WorkspaceExportButton />
      </FieldRow>

      <Modal
        title="Download Report"
        visible={Boolean(selectedReport)}
        okButtonProps={{ disabled: !Boolean(selectedRange), loading, }}
        onOk={handleOk}
        onCancel={handleClose}
        destroyOnClose
      >
        <RangePicker onChange={handleSelectedDate} style={{ width: '100%' }}/>
      </Modal>
    </div>
  )
}
