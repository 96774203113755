import React from 'react'

export const Caption = (props: { children: React.ReactNode }) => (
  <div
    style={{
      color: '#c5c5c5',
      fontWeight: 'lighter',
      lineHeight: 'normal',
      marginTop: '10px'
    }}
  >
    {props.children}
  </div>
)
