import React from 'react'

type PageCenterProps = {
  children: React.ReactNode
}


export function PageCenter(props: PageCenterProps) {
  return (
    <div
      style={{
        width: '400px',
        height: '400px',
        position: 'absolute',
        top: '0',
        bottom: 0,
        left: 0,
        right: 0,
        margin: 'auto'
      }}
    >
      {props.children}
    </div>
  )
}
