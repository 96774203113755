import DownloadOutlined from '@ant-design/icons/DownloadOutlined'
import Button from 'antd/es/button'
import message from 'antd/es/message'
import * as FileSaver from 'file-saver'
import React, { useEffect, useState } from 'react'
import csv from 'react-csv-downloader/dist/lib/csv'
import { IWorkspace } from '../types'
import { getUserRoleForWorkspace } from '../utils'
import * as UserRules from '../rules/UserRules'
import useUsers from '../hooks/useUsers'

type Props = {
  readonly workspace: IWorkspace
}

export default function UserInWorkspaceExportButton(props: Props) {
  const [loading, setLoading] = useState(false)

  return (
    <React.Fragment>
      <Button
        type="primary"
        icon={<DownloadOutlined />}
        onClick={() => {
          setLoading(true)
        }}
        loading={loading}
      >
        Export to CSV
      </Button>
      {loading && <Downloader {...props} onDone={() => { setLoading(false) }} />}
    </React.Fragment>
  )
}

function Downloader(props: {
  readonly workspace: IWorkspace
  readonly onDone: () => void
}) {
  const { userWrappers, loading } = useUsers({ workspaceId: String(props.workspace._id) })

  useEffect(() => {
    if (!loading) {
      const rows = userWrappers.map(({ user, invitedBy }) => {
        return {
          email: user.email,
          display_name: user.display_name,
          job_title: user.job_title,
          status: UserRules.getUserDisplayStatus(user),
          lastLogin: user.last_login ? new Date(user.last_login).toISOString() : '',
          created: new Date(user.created).toISOString(),
          invitedBy: invitedBy || '',
          role: getUserRoleForWorkspace(String(user._id), props.workspace),
          workspaceStatus: UserRules.getUserWorkspaceDisplayStatus(user, props.workspace.member_profiles),
        }
      })

      const columns: readonly { readonly id: keyof Unpacked<typeof rows>; readonly displayName: string }[] = [
        {
          id: 'email',
          displayName: 'Email'
        },
        {
          id: 'display_name',
          displayName: 'Full name'
        },
        {
          id: 'job_title',
          displayName: 'Job title'
        },
        {
          id: 'status',
          displayName: 'User status'
        },
        {
          id: 'lastLogin',
          displayName: 'Last login'
        },
        {
          id: 'created',
          displayName: 'Created'
        },
        {
          id: 'invitedBy',
          displayName: 'Invited by'
        },
        {
          id: 'role',
          displayName: 'Role'
        },
        {
          id: 'workspaceStatus',
          displayName: 'Workspace member status'
        }
      ]

      const fileName: string = `${props.workspace.name}-users-${new Date().toISOString()}`

      csv({ columns: columns as any, datas: rows }).then((fileText) => {
        props.onDone()

        // Copied from https://github.com/dolezel/react-csv-downloader/blob/c7cd43271908f721ac25f0aed999bd09996a61f6/src/index.tsx
        const blob = new Blob([fileText as string], { type: 'text/csv;charset=utf-8' })
        FileSaver.saveAs(blob, fileName + '.csv', { autoBom: true })
      }).catch(() => {
        message.error('Could not generate CSV file. Please contact support@taskworld.com')
      })
    }
  }, [loading])

  return null
}
