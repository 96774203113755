import _ from 'lodash'
import { useEffect, useState } from 'react'
import * as AuthServices from '../services/AuthServices'
import { IApiWorkspaceProjects, IApiWorkspaceProjectsResult } from '../types'

const fetchProjects = async (workspaceId: string): Promise<IApiWorkspaceProjectsResult> => {
  const client = AuthServices.getAuthenticatedApiConnector()
  return client.get<IApiWorkspaceProjectsResult>(`workspaces/${workspaceId}/projects`)
}

export default function useProjects (workspaceId: string) {
  const [projects, setProjects] = useState<IApiWorkspaceProjects[]>([])

  useEffect(() => {
    const fetchData = async () => {
      const result = await fetchProjects(workspaceId);

      setProjects(result.projects);
    };

    fetchData();
  }, [workspaceId]);

  return projects;
}
