import { IWorkspaceMemberProfile, IWorkspace } from "../types/WorkspaceTypes"
import _ from 'lodash'

export function isUserRemoved(workspace: IWorkspace, userId: string) {
  return isUserRemovedByProfiles(workspace.member_profiles, userId)
}

export function isUserRemovedByProfiles(memberProfiles: readonly IWorkspaceMemberProfile[], userId: string) {
  const memberProfile = _.find(memberProfiles, (profile: IWorkspaceMemberProfile) => profile._id === userId)

  return _.get(memberProfile, 'is_removed', false)
}

export function getUserRoleForWorkspace(userId: string, workspace: IWorkspace): string {
  if (isWorkspaceAdmin(workspace, userId)) {
    return "ADMIN"
  }

  if (isWorkspaceUser(workspace, userId)) {
    return "MEMBER"
  }

  return ""
}

export function isWorkspaceAdmin(workspace: IWorkspace, userId: string) {
  return workspace.admins ? workspace.admins.includes(userId) : false
}

export function isWorkspaceUser(workspace: IWorkspace, userId: string) {
  return workspace.members ? workspace.members.includes(userId) : false
}

export function isUserAwaitForConfirmationByProfiles(memberProfiles: IWorkspaceMemberProfile[], userId: string) {
  return getAwaitingConfirmationUserIds(memberProfiles).includes(userId)
}

export function getAwaitingConfirmationUserIds(memberProfiles: IWorkspaceMemberProfile[]): readonly string[] {
  return _(memberProfiles)
    .filter((profile: IWorkspaceMemberProfile): boolean => profile.awaiting_confirmation && !profile.is_removed)
    .map((profile: IWorkspaceMemberProfile) => profile._id)
    .value()
}
