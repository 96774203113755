import _ from 'lodash'
import React, { Dispatch } from 'react'
import { Modal, Select, Divider } from 'antd'
import { taskworldColors } from '../colors'
import { IApiWorkspaceProjects, IWorkspaceProjects } from '../types'

type ConvertMembersToGuestsFunction = (
  selectedRowKeys: string[],
  workspaceId: string,
  projectId: string | undefined
) => Promise<void>;

export default function MembersToGuestsWorkspaceModal(data: {
  selectedRowKeys: string[], 
  workspaceId: string, 
  convertMembersToGuests: ConvertMembersToGuestsFunction,
  setSelectedRowKeys: Dispatch<string[]>
  projects: IApiWorkspaceProjects[],
}) {
  return () => {
    const convertMemberToGuestBtn = {
      disabledConvertToGuest: true,
      projectId: undefined,
    }
  
    const modal =  Modal.confirm({
      onOk: async () => {
        await data.convertMembersToGuests(data.selectedRowKeys, data.workspaceId, convertMemberToGuestBtn.projectId)
          .then(() => data.setSelectedRowKeys([]))
      },
      centered: true,
      title: `Convert ${data.selectedRowKeys.length} Member to Guest`,
      okType: 'primary',
      okButtonProps: {
        ...(!convertMemberToGuestBtn.disabledConvertToGuest && {
          style: { backgroundColor: taskworldColors.primary }
        }),
        disabled: convertMemberToGuestBtn.disabledConvertToGuest
      },
      okText: 'Convert to Guest',
      closable: true,
      cancelText: 'Cancel',
      icon: null,
      className: "convert-member-to-guest-modal",
      content: (
        <>
          <Divider />
          <p>please select a project to assign guests</p>
          <Select
            options={
              data.projects.reduce((accumulator: IWorkspaceProjects[], currentValue: IApiWorkspaceProjects) => 
              accumulator.concat({ value: currentValue._id.toString(), label: currentValue.title})
            , [])}
            placeholder="Select a project"
            style={{ width: '100%' }}
            onChange={(value) => {
              convertMemberToGuestBtn.disabledConvertToGuest = !value
              convertMemberToGuestBtn.projectId = value
              
              modal.update({
                okButtonProps: { 
                  ...(!convertMemberToGuestBtn.disabledConvertToGuest ? {
                    style: { backgroundColor: taskworldColors.primary }
                  } : { 
                    style: { 
                      backgroundColor: taskworldColors.btnDisabled,
                      color: taskworldColors.btnTextDisabled
                    } 
                  })
                }
              })
          }}
        />
        <p style={{ color: taskworldColors.btnTextDisabled}}>
          Once members turn to guests, they can access only the selected project and lose access from previous ones.
        </p>
        </>
      )
    })
  
    return modal;
  }
}
