import React from 'react'

import { Toggle } from '../../uikit'

export interface IFeatureToggleTabProps {
  timeTrackingEnabled: boolean
  onUpdateTimeTrackEnabled: (checked: boolean) => Promise<void>

  projectReportEnabled: boolean
  onUpdateProjectReportEnabled: (checked: boolean) => Promise<void>

  accountDeletionEnabled: boolean
  onAccountDeletionEnabled: (checked: boolean) => Promise<void>

  transferOwnershipEnabled: boolean
  onTransferOwnershipEnabled: (checked: boolean) => Promise<void>

  forceProjectAdminTransfer: boolean
  onAllProjectAdminTransferEnabled: (checked: boolean) => Promise<void>

  userExportEnabled: boolean
  onUserExportToggle: (enabled: boolean) => Promise<void>
}

export default function(props: IFeatureToggleTabProps) {
  return (
    <React.Fragment>
      <Toggle
        title="Time Tracking"
        checked={props.timeTrackingEnabled}
        onChange={props.onUpdateTimeTrackEnabled}
      />
      <Toggle
        title="Project Reporting"
        checked={props.projectReportEnabled}
        onChange={props.onUpdateProjectReportEnabled}
      />
      <Toggle
        title="Account Deletion"
        checked={props.accountDeletionEnabled}
        onChange={props.onAccountDeletionEnabled}
      />
      <Toggle
        title="Ownership Transfer"
        caption={
          <p>
            By turning this off,{' '}
            <a href="https://support.taskworld.com/hc/en-us/articles/360000485646-Transferring-Workspace-Admin-Ownership">
              ownership transfer menu
            </a>{' '}
            will be hidden
          </p>
        }
        checked={props.transferOwnershipEnabled}
        onChange={props.onTransferOwnershipEnabled}
      />
      <Toggle
        title="Force Transfer All Project Admins"
        caption={
          <p>
            By turning this on,{' '}
            <a href="https://support.taskworld.com/hc/en-us/articles/115004026586-Removing-a-Workspace-Member">
              removing a user from a workspace
            </a>{' '}
            will require you to assign new project admins to every project that will no longer have admins
          </p>
        }
        checked={props.forceProjectAdminTransfer}
        onChange={props.onAllProjectAdminTransferEnabled}
      />
      <Toggle
        title="Workspace user export"
        checked={props.userExportEnabled}
        onChange={props.onUserExportToggle}
      />
    </React.Fragment>
  )
}
