import React from 'react'
import Col from 'antd/es/col'
import Button from 'antd/es/button'
import Radio, { RadioChangeEvent } from 'antd/es/radio'
import formatISO from 'date-fns/formatISO'

import { FieldRow } from '../../uikit'

export interface ILicenseTabProps {
  expiryDate: string
  userLimit: number
  activeUser: number
  activeGuest: number
  superAdmins: string
  saving: boolean
  onlyWorkspaceAdminCanInvite: boolean
  allUsersCanCreateWorkspace: boolean

  onSave: () => void
  onInviteChange: (e: RadioChangeEvent) => void
  onWorkspaceCreatorChange: (e: RadioChangeEvent) => void
}

export default function(props: ILicenseTabProps) {
  return (
    <div>
      <FieldRow>
        <Col span={8}>Expiry date:</Col>
        <Col style={{ fontWeight: 'bold' }} span={16}>
          {formatISO(new Date(props.expiryDate), { representation: 'date' })}
        </Col>
      </FieldRow>
      <FieldRow>
        <Col span={8}>User limit:</Col>
        <Col style={{ fontWeight: 'bold' }} span={16}>
          {props.userLimit}
        </Col>
      </FieldRow>
      <FieldRow>
        <Col span={8}>Number of users:</Col>
        <Col style={{ fontWeight: 'bold' }} span={16}>
          {props.activeUser}
        </Col>
      </FieldRow>
      <FieldRow>
        <Col span={8}>Number of guests:</Col>
        <Col style={{ fontWeight: 'bold' }} span={16}>
          {props.activeGuest}
        </Col>
      </FieldRow>
      <FieldRow>
        <Col span={8}>Who can invite people into workspace:</Col>
        <Col style={{ fontWeight: 'bold' }} span={16}>
          <Radio.Group value={props.onlyWorkspaceAdminCanInvite ? 1 : 0} onChange={props.onInviteChange}>
            <Radio value={1}>Only workspace admins</Radio>
            <Radio value={0}>All workspace members</Radio>
          </Radio.Group>
        </Col>
      </FieldRow>
      <FieldRow>
        <Col span={8}>Who can create workspace:</Col>
        <Col style={{ fontWeight: 'bold' }} span={16}>
          <Radio.Group value={props.allUsersCanCreateWorkspace ? 1 : 0} onChange={props.onWorkspaceCreatorChange}>
            <Radio value={1}>Everyone</Radio>
            <Radio value={0}>Only specific users</Radio>
          </Radio.Group>
        </Col>
      </FieldRow>
      <FieldRow>
        <Col span={8}>Super admins (having access to every project):</Col>
        <Col style={{ fontWeight: 'bold' }} span={16}>
          {props.superAdmins}
        </Col>
      </FieldRow>
      <div style={{ marginTop: '30px' }}>
        <Button htmlType="button" type="primary" loading={props.saving} onClick={props.onSave}>
          Save
        </Button>
      </div>
    </div>
  )
}
