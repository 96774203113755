import React from 'react'
import { HashRouter as Router, Switch } from 'react-router-dom'

import _ from 'lodash'
import './App.css'
import ErrorBoundary from './components/ErrorBoundary'
import { EnvFetcher } from './hooks/useEnv'
import MainLayout from './MainLayout'
import { RouteInternal, RedirectInternal } from './routes'
import { isAuthen } from './services/AuthServices'
import { ConfigProvider } from 'antd'
import { taskworldColors } from './colors'

export default function App() {
  return (
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: taskworldColors.primary,
          colorLink: taskworldColors.primary,
          colorLinkHover: taskworldColors.primaryHover,
          colorError: taskworldColors.danger
        }
      }}
    >
      <ErrorBoundary>
        <EnvFetcher>
          <Router>
            <Switch>
              <RouteInternal path="/login" />
              <RouteInternal path="/logout" />
              <AuthenticationGuard>
                <MainLayout>
                  <Switch>
                    <RouteInternal path="/users/:userId" />
                    <RouteInternal path="/users" />
                    <RouteInternal path="/workspaces/:workspaceId" />
                    <RouteInternal path="/workspaces" />
                    <RouteInternal path="/license" />
                    {/* Fallback route */}
                    <RedirectInternal to="/users" />
                  </Switch>
                </MainLayout>
              </AuthenticationGuard>
            </Switch>
          </Router>
        </EnvFetcher>
      </ErrorBoundary>
    </ConfigProvider>
  )
}

function AuthenticationGuard(props: { readonly children: React.ReactElement }) {
  if (!isAuthen()) {
    return <RedirectInternal to="/login" />
  }

  return props.children
}
