import React from 'react'
import Row from 'antd/es/row'

type FieldRowProps = {
  children: React.ReactNode
}

export function FieldRow(props: FieldRowProps) {
  return <Row style={{ margin: '10px 0' }}>{props.children}</Row>
}
