import React, { useEffect, useRef } from 'react'

import Input from 'antd/es/input'
import { FilterDropdownProps } from 'antd/es/table/interface'

export default function TableColumnSearch(props: FilterDropdownProps) {
  const inputRef = useRef<Input>(null)

  useEffect(() => {
    if (props.visible) {
      // Wait until the <Input.Search> is mounted
      requestAnimationFrame(() => {
        // Emulate `autoFocus` prop as the prop did not work at the second time onwards
        inputRef.current?.input.focus()
      })
    }
  }, [props.visible])

  if (!props.visible) {
    // Unmount the <Input.Search> below as its `defaultValue` prop must be reset when re-opening
    return null
  }

  const onSearch = (value: string) => {
    value = value.trim()

    props.setSelectedKeys(value ? [value] : [])
    props.confirm({ closeDropdown: true })
  }

  return (
    <div style={{ padding: 8 }}>
      <Input.Search
        ref={inputRef}
        defaultValue={props.selectedKeys[0]}
        onSearch={onSearch}
        enterButton
        allowClear
      />
    </div>
  )
}
