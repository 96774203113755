import { IUserWrapper, IWorkspaceMemberProfile } from '../types'
import { isUserAwaitForConfirmationByProfiles, isUserRemovedByProfiles } from '../utils'

export enum UserStatus {
  USER_STATUS_ACTIVE = 0,
  USER_STATUS_PENDING = -1,
  USER_STATUS_TERMINATE = -2
}

enum UserDisplayStatus {
  ACTIVE = 'Active',
  PENDING = 'Pending',
  TERMINATED = 'Terminated',
  UNKNOWN = 'Unknown'
}

export enum UserWorkspaceDisplayStatus {
  ACTIVE = 'Active',
  REMOVED = 'Removed',
  AWAITING_CONFIRMATION = 'Awaiting confirmation'
}

export function getUserDisplayStatus(user: Pick<IUserWrapper['user'], 'status'>): UserDisplayStatus {
  switch (user.status) {
    case UserStatus.USER_STATUS_ACTIVE:
      return UserDisplayStatus.ACTIVE
    case UserStatus.USER_STATUS_PENDING:
      return UserDisplayStatus.PENDING
    case UserStatus.USER_STATUS_TERMINATE:
      return UserDisplayStatus.TERMINATED
    default:
      return UserDisplayStatus.UNKNOWN
  }
}

/**
 * @deprecated usage of workspace member_profiles is deprecated, use getUserWorkspaceDisplayStatusV2
 */
export function getUserWorkspaceDisplayStatus(
  user: Pick<IUserWrapper['user'], '_id'>,
  workspaceMemberProfiles: IWorkspaceMemberProfile[]
): UserWorkspaceDisplayStatus {
  if (isUserRemovedByProfiles(workspaceMemberProfiles, user._id)) {
    return UserWorkspaceDisplayStatus.REMOVED
  }
  if (isUserAwaitForConfirmationByProfiles(workspaceMemberProfiles, user._id)) {
    return UserWorkspaceDisplayStatus.AWAITING_CONFIRMATION
  }

  return UserWorkspaceDisplayStatus.ACTIVE
}

