import SearchOutlined from '@ant-design/icons/SearchOutlined'
import Table, { ColumnProps, TableProps } from 'antd/es/table'
import { Modal, Space } from 'antd'
import formatDate from 'date-fns/format'
import _ from 'lodash'
import React, { useMemo } from 'react'
import { IWorkspaceWrapper, IUserWrapper } from '../types'
import * as UserRules from '../rules/UserRules'
import TableColumnSearch from '../components/TableColumnSearch'
import UserActions from '../components/UserActions'
import usePersistedTableStateInfo from '../hooks/usePersistedTableStateInfo'
import useUsers from '../hooks/useUsers'
import useEnv from '../hooks/useEnv'
import { MFAEnabledIcon } from '../uikit'

const { confirm } = Modal

export default function UsersTable(props: {
  readonly workspaceWrapper?: IWorkspaceWrapper
  readonly includeCountedAs?: boolean
  readonly includeNumberOfWorkspaces?: boolean
  readonly verticalScrollHeight?: string | number
  readonly role?: string
  readonly rowSelection?: TableProps<IUserWrapper>['rowSelection']
  header: (allMatchingUserIds: string[]) => React.ReactNode
}) {
  const workspaceId = props.workspaceWrapper ? String(props.workspaceWrapper.workspace._id) : undefined

  const originalColumns: readonly ColumnProps<IUserWrapper>[] = useMemo(
    () =>
      _.compact([
        {
          key: 'action',
          render: (_, { user, mfa, disableMFA }) => (
            <UserActions
              userId={user._id}
              workspaceWrapper={props.workspaceWrapper}
              mfaEnabled={mfa}
              onDisableMFAClick={() =>
                confirm({
                  icon: null,
                  title: 'Confirm to continue',
                  content:
                    'You would allow this member to access the workspace without Two-Factor Authentication. Are you sure to disable this step?',
                  onOk: () => disableMFA && disableMFA(),
                  className: 'confirm-modal'
                })
              }
            />
          ),
          width: 8 + 32 + 8, // 8 is from <Table size="small">
          fixed: true
        },
        {
          key: 'email',
          title: 'Email',
          render: (_, { user }) => {
            const [initial, domain] = user.email.split('@')

            return (
              <React.Fragment>
                {initial}
                <wbr />@{domain}
              </React.Fragment>
            )
          },
          filterDropdown: TableColumnSearch,
          filterIcon: <SearchOutlined />,
          sorter: true,
          width: 220,
          fixed: true
        },
        {
          key: 'name',
          title: 'Name',
          render: (_, { user, mfa }) => {
            const name = user.first_name && user.last_name ? `${user.first_name} ${user.last_name}` : ''
            if (!mfa) {
              return name
            }
            return (
              <>
                <span>{name}</span>
                <MFAEnabledIcon />
              </>
            )
          },
          filterDropdown: TableColumnSearch,
          filterIcon: <SearchOutlined />,
          sorter: true,
          width: 150
        },
        {
          key: 'job_title',
          title: 'Job title',
          render: (_, { user }) => user.job_title,
          filterDropdown: TableColumnSearch,
          filterIcon: <SearchOutlined />,
          sorter: true,
          width: 150
        },
        {
          key: 'status',
          title: 'User status',
          render: (_, { user }) => UserRules.getUserDisplayStatus(user),
          sorter: true,
          width: 140,
          filters: [
            {
              text: 'Active',
              value: UserRules.UserStatus.USER_STATUS_ACTIVE
            },
            {
              text: 'Pending',
              value: UserRules.UserStatus.USER_STATUS_PENDING
            }
          ]
        },
        {
          key: 'last_login',
          title: 'Last login',
          render: (_, { user }) => {
            return user.last_login ? formatDate(new Date(user.last_login), 'MMM d, y hh:mm a') : ''
          },
          sorter: true,
          width: 120
        },
        {
          key: 'created',
          title: 'Created',
          render: (_, { user }) => {
            return formatDate(new Date(user.created), 'MMM d, y hh:mm a')
          },
          sorter: true,
          width: 120
        },
        {
          key: 'invitedBy',
          title: 'Invited by',
          render: (_, { invitedBy }) => invitedBy,
          width: 220
        },
        props.includeCountedAs && {
          key: 'countedAs',
          title: 'Counted as',
          render: (_, { countedAs }) => countedAs,
          width: 100
        },
        props.includeNumberOfWorkspaces && {
          key: 'numberOfWorkspacesAsMember',
          title: 'Num of ws as member',
          render: (_, { numberOfWorkspacesAsMember }) => numberOfWorkspacesAsMember,
          align: 'right' as const,
          width: 100
        },
        props.includeNumberOfWorkspaces && {
          key: 'includeNumberOfWorkspaces',
          title: 'Num of ws as admin',
          render: (_, { numberOfWorkspacesAsAdmin }) => numberOfWorkspacesAsAdmin,
          align: 'right' as const,
          width: 100
        },
        props.includeNumberOfWorkspaces && {
          key: 'numberOfWorkspacesAsGuest',
          title: 'Num of ws as guest',
          render: (_, { numberOfWorkspacesAsGuest }) => numberOfWorkspacesAsGuest,
          align: 'right' as const,
          width: 100
        },
        props.workspaceWrapper && {
          key: 'role',
          title: 'Role',
          render: (_, { roles }) => (workspaceId && roles[workspaceId]) || '-',
          width: 150,
          filterMultiple: false,
          filters: [
            {
              text: 'ADMIN',
              value: 'ADMIN'
            },
            {
              text: 'GUEST',
              value: 'GUEST'
            },
            {
              text: 'MEMBER',
              value: 'MEMBER'
            }
          ]
        },
        props.workspaceWrapper && {
          key: 'workspaceStatus',
          title: 'Workspace member status',
          render: (_, { user }) => {
            return UserRules.getUserWorkspaceDisplayStatus(
              user,
              props.workspaceWrapper?.workspace.member_profiles || []
            )
          },
          width: 220
        }
      ]),
    [props]
  )

  const { columns, pageIndex, onChange } = usePersistedTableStateInfo(originalColumns)

  const getFilteredValue = (key: string) => columns.find((column) => column.key === key)?.filteredValue || undefined

  const pageSizes = {
    SumOfMainLayoutMargin: 60,
    PaginationHeight: 56,
    FooterHeight: 70,
    RowHeight: 60,
    ColumnHeader: 1
  }

  const pageChunk = Math.max(
    Math.floor(
      (window.innerHeight - pageSizes.SumOfMainLayoutMargin - pageSizes.PaginationHeight - pageSizes.FooterHeight) /
        pageSizes.RowHeight
    ) - pageSizes.ColumnHeader,
    5
  )

  const sortColumn = columns.find((column) => column.sortOrder)
  const mfaStatus = !!useEnv().MFA_ENABLED

  const { userWrappers, loading, totalMatchingUserCount, disableMFA, allMatchingUserIds } = useUsers({
    workspaceId,
    email: getFilteredValue('email')?.join(' '),
    first_name: getFilteredValue('first_name')?.join(' '),
    last_name: getFilteredValue('last_name')?.join(' '),
    name: getFilteredValue('name')?.join(' ').trim(),
    job_title: getFilteredValue('job_title')?.join(' '),
    status: getFilteredValue('status')?.map((value) => parseInt(String(value))),
    role: getFilteredValue('role')?.join(',') || props.role,
    pageIndex,
    pageChunk,
    sortColumn: sortColumn ? String(sortColumn.key) : undefined,
    sortOrder: sortColumn?.sortOrder,
    mfaStatus
  })

  const userWrapperWithDisableMFA = userWrappers.map((data) => {
    if (mfaStatus) {
      return { ...data, disableMFA: () => disableMFA(data.user._id, data.user.display_name) }
    }
    return data
  })

  return (
    <Space direction="vertical" size="middle" style={{ display: 'flex' }}>
      {props.header && props.header(allMatchingUserIds)}
      <Table
        rowSelection={props.rowSelection}
        rowKey={(record) => String(record.user._id)}
        columns={columns}
        dataSource={userWrapperWithDisableMFA}
        loading={loading}
        pagination={{
          current: pageIndex,
          total: totalMatchingUserCount,
          defaultPageSize: pageChunk,
          showQuickJumper: true,
          showSizeChanger: false
        }}
        size="small"
        scroll={{
          x: columns.reduce((totalWidth: number, column: ColumnProps<any>) => {
            return totalWidth + Number(column.width || 0)
          }, 0)
        }}
        onChange={onChange}
      />
    </Space>
  )
}
