import { ColumnProps, TableProps } from 'antd/es/table'
import { produce } from 'immer'
import _ from 'lodash'
import { useCallback, useMemo } from 'react'
import { useHistory } from 'react-router-dom'
import { useUrlState } from 'with-url-state'

const multiValueSeparator = ','

export default function usePersistedTableStateInfo<T>(originalColumns: readonly ColumnProps<T>[]) {
  const history = useHistory()
  const [urlState, setUrlState] = useUrlState<any>({}, { history })

  const onChange: NonNullable<TableProps<T>['onChange']> = useCallback(
    (pagination, filters, sorter) => {
      const primarySorter = _.flatten([sorter])[0]

      setUrlState(prevUrlState => ({
        ..._.mapValues(filters, (value) => (_.isArray(value) ? value.join(multiValueSeparator) : value) || undefined),
        pageIndex: getNonFirstPageIndex(prevUrlState.pageIndex, pagination.current),
        sortColumn: (primarySorter.order && String(primarySorter.columnKey)) || undefined,
        sortOrder: primarySorter.order || undefined
      }))
    },
    [setUrlState]
  )

  const columns = useMemo(
    () => originalColumns.map((column) => {
      return produce(column, (draft) => {
        if (!draft.key) {
          return
        }

        if ((draft.filters || draft.onFilter || draft.filterDropdown)) {
          // Keep passing `null` when no filters as passing `undefined` would not clear the previous filter value
          draft.filteredValue = urlState[draft.key]
            ? urlState[draft.key].split(multiValueSeparator)
            : null
        }

        if (draft.sorter && urlState.sortColumn === String(draft.key) && urlState.sortOrder) {
          draft.sortOrder = urlState.sortOrder === 'descend' ? 'descend' : 'ascend'
        }
      })
    }),
    [originalColumns, urlState]
  )

  const pageIndex = useMemo(
    () => isNaN(urlState.pageIndex) ? 1 : parseInt(urlState.pageIndex),
    [urlState]
  )

  return { columns, pageIndex, onChange }
}

function getNonFirstPageIndex(prevPageIndex: string | undefined, nextPageIndex: number | undefined) {
  if (nextPageIndex !== undefined && nextPageIndex > 1) {
    return nextPageIndex
  }

  // Avoid showing pageIndex=1 in the address bar needlessly
  if (prevPageIndex === undefined || prevPageIndex === '1') {
    return undefined
  }

  return nextPageIndex
}
