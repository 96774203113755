import DeleteOutlined from '@ant-design/icons/DeleteOutlined'
import DownOutlined from '@ant-design/icons/DownOutlined'
import SearchOutlined from '@ant-design/icons/SearchOutlined'
import Alert from 'antd/es/alert'
import Button from 'antd/es/button'
import Col from 'antd/es/col'
import Dropdown from 'antd/es/dropdown'
import Input from 'antd/es/input'
import message from 'antd/es/message'
import Modal from 'antd/es/modal'
import { PageHeader } from '@ant-design/pro-layout'
import Switch from 'antd/es/switch'
import Table, { ColumnsType, TableProps } from 'antd/es/table'
import formatDate from 'date-fns/format'
import _ from 'lodash'
import React, { useState } from 'react'
import { RouteComponentProps, useHistory } from 'react-router-dom'
import { IWorkspaceWrapper } from '../types'
import { UserStatus } from '../rules/UserRules'
import TableColumnSearch from '../components/TableColumnSearch'
import UserInWorkspaceRemovalModal from '../components/UserInWorkspaceRemovalModal'
import useEnv from '../hooks/useEnv'
import useUsers from '../hooks/useUsers'
import useWorkspaces from '../hooks/useWorkspaces'
import { IUserWrapper } from '../types'
import { Card, FieldRow, LoadingStatePage, MFAEnabledIcon } from '../uikit'
import { ConfigProvider, Space, Typography } from 'antd'
import { taskworldColors } from '../colors'
import NotFound from '../components/NotFound'

type RouteParams = { readonly userId: string }

const { confirm } = Modal

export default function UserPage(props: RouteComponentProps<RouteParams>): React.ReactElement {
  const {
    userWrappers,
    updateAdminVpc,
    updateCanCreateWorkspace,
    updateResetPasswordLink,
    removeUserAccount,
    updateUserWorkspaceRole,
    disableMFA,
    loading
  } = useUsers({ userId: props.match.params.userId, mfaStatus: !!useEnv().MFA_ENABLED })
  const history = useHistory()

  if (userWrappers.length === 0 && !loading) {
    return <NotFound>Sorry, the user you're looking for does not exist.</NotFound>
  }

  // Do not use `loading` returned from `useUsers` hook
  // as we do not want the whole page to be flashed when updating any of user fields
  if (userWrappers.length === 0) {
    return <LoadingStatePage />
  }

  return (
    <UserPageInternal
      userWrapper={userWrappers[0]}
      history={history}
      updateAdminVpc={updateAdminVpc}
      updateCanCreateWorkspace={updateCanCreateWorkspace}
      updateResetPasswordLink={updateResetPasswordLink}
      removeUserAccount={removeUserAccount}
      updateUserWorkspaceRole={updateUserWorkspaceRole}
      mfa={userWrappers[0].mfa}
      disableMFA={disableMFA}
    />
  )
}

export function UserPageInternal(props: {
  readonly userWrapper: IUserWrapper
  readonly history: ReturnType<typeof useHistory>
  readonly updateAdminVpc: (userId: string, value: boolean) => Promise<void>
  readonly updateCanCreateWorkspace: (userId: string, value: boolean) => Promise<void>
  readonly updateResetPasswordLink: (userId: string) => Promise<string>
  readonly removeUserAccount: (userId: string) => Promise<void>
  readonly updateUserWorkspaceRole: (userId: string, workspaceId: string, role: 'ADMIN' | 'MEMBER') => Promise<void>
  readonly mfa: undefined | boolean
  readonly disableMFA: (userId: string, userName: string) => void | Promise<void>
}) {
  const [resetPasswordLink, setResetPasswordLink] = useState('')
  const [resetPasswordLinkLoading, setResetPasswordLinkLoading] = useState(false)
  const [resetPasswordLinkError, setResetPasswordLinkError] = useState('')
  const [removeUserConfirmationModalVisible, setRemoveUserConfirmationModalVisible] = useState(false)
  const [grantAdminPermissionModalVisible, setGrantAdminPermissionModalVisible] = useState(false)
  const [allAccessToggling, setAllAccessToggling] = useState(false)
  const [removingUser, setRemovingUser] = useState(false)
  const [selectedRowKeys, setSelectedRowKeys] = useState<string[]>([])
  const [removingWorkspaceIds, setRemovingWorkspaceIds] = useState<string[]>([])

  const env = useEnv()

  const { user } = props.userWrapper

  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    setSelectedRowKeys(newSelectedRowKeys as string[])
  }

  const onWorkspaceCreationRightChange = async (userId: string, canCreateWorkspace: boolean) => {
    try {
      await props.updateCanCreateWorkspace(userId, canCreateWorkspace)
    } catch (error) {
      message.error(String(error))
    }
  }

  const onResetPasswordButtonClick = async () => {
    setResetPasswordLinkLoading(true)
    setResetPasswordLinkError('')
    try {
      const resetPasswordLink = await props.updateResetPasswordLink(user._id)
      setResetPasswordLink(resetPasswordLink)
    } catch (error) {
      setResetPasswordLinkError((error as Error).message)
    } finally {
      setResetPasswordLinkLoading(false)
    }
  }

  const onAllAccessToggle = () => {
    setGrantAdminPermissionModalVisible(true)
  }

  const onAllAccessCancel = () => {
    setGrantAdminPermissionModalVisible(false)
  }

  const deleteUserAccount = async () => {
    setRemovingUser(true)
    try {
      await props.removeUserAccount(user._id)
      message.success('User has been deleted')
      props.history.goBack()
    } catch (error) {
      message.error('Something went wrong, could not remove the user')
    } finally {
      setRemovingUser(false)
    }
  }

  const updateUserWorkspaceRole = async (userId: string, workspaceId: string, role: 'ADMIN' | 'MEMBER') => {
    try {
      await props.updateUserWorkspaceRole(userId, workspaceId, role)
      message.success('User has been updated')
    } catch (error) {
      message.error('Something wen wrong, could not update the user')
    }
  }

  const onAllAccessConfirm = async () => {
    setAllAccessToggling(true)
    try {
      await props.updateAdminVpc(user._id, !user.is_vpc_admin)
      setGrantAdminPermissionModalVisible(false)
    } catch (error) {
      message.error(String(error))
    } finally {
      setAllAccessToggling(false)
    }
  }

  const renderModal = () => {
    if (user.is_vpc_admin) {
      return (
        <Modal
          title="All access privilege"
          visible={grantAdminPermissionModalVisible}
          confirmLoading={allAccessToggling}
          maskClosable={!allAccessToggling}
          closable={!allAccessToggling}
          okType="danger"
          okText="Confirm"
          centered
          onOk={onAllAccessConfirm}
          onCancel={onAllAccessCancel}
        >
          By revoking all access privilege, <strong>{user.email}</strong> will...
          <ul>
            <li>have no access the VPC admin</li>
            <li>no longer be a workspace admin of every workspace but remain a workspace member</li>
            <li>no longer be a project admin of every project but remain a project member</li>
          </ul>
        </Modal>
      )
    }

    return (
      <Modal
        title="All access privilege"
        visible={grantAdminPermissionModalVisible}
        confirmLoading={allAccessToggling}
        maskClosable={!allAccessToggling}
        closable={!allAccessToggling}
        okText="Confirm"
        centered
        onOk={onAllAccessConfirm}
        onCancel={onAllAccessCancel}
      >
        By granting all access privilege, <strong>{user.email}</strong> will...
        <ul>
          <li>have access the VPC admin using their own credential</li>
          <li>become a workspace admin of every workspace</li>
          <li>become a project admin of every project</li>
        </ul>
      </Modal>
    )
  }

  const renderRemoveUserConfirmationModal = () => {
    return (
      <Modal
        title="Remove user from all workspaces and delete account"
        visible={removeUserConfirmationModalVisible}
        okText="Confirm"
        cancelText="Cancel"
        centered
        okButtonProps={{
          disabled: removingUser,
          danger: true,
          style: { backgroundColor: taskworldColors.danger }
        }}
        cancelButtonProps={{ disabled: removingUser }}
        onOk={deleteUserAccount}
        onCancel={() => setRemoveUserConfirmationModalVisible(false)}
      >
        <p>You cannot undo this action. Are you sure to continue?</p>
      </Modal>
    )
  }

  return (
    <div style={{ color: taskworldColors.gray500 }}>
      <PageHeader title={`User: ${user.display_name}`} onBack={() => props.history.goBack()} />
      <Card>
        <FieldRow>
          <Col span={8}>Email</Col>
          <Col style={{ fontWeight: 'bold' }} span={16}>
            {user.email}
          </Col>
        </FieldRow>
        <FieldRow>
          <Col span={8}>Full name</Col>
          <Col style={{ fontWeight: 'bold' }} span={16}>
            {user.display_name}
            {props.mfa && <MFAEnabledIcon />}
          </Col>
        </FieldRow>
        <FieldRow>
          <Col span={8}>Job title</Col>
          <Col style={{ fontWeight: 'bold' }} span={16}>
            {user.job_title}
          </Col>
        </FieldRow>
        <FieldRow>
          <Col span={8}>Department</Col>
          <Col style={{ fontWeight: 'bold' }} span={16}>
            {user.department}
          </Col>
        </FieldRow>
        <FieldRow>
          <Col span={8}>Last login</Col>
          <Col style={{ fontWeight: 'bold' }} span={16}>
            {user.last_login ? formatDate(new Date(user.last_login), 'MMM d, y hh:mm a') : ''}
          </Col>
        </FieldRow>
        <FieldRow>
          <Col span={8}>External User (ADFS, etc.)</Col>
          <Col style={{ fontWeight: 'bold' }} span={16}>
            {user.can_change_password ? 'No' : 'Yes'}
          </Col>
        </FieldRow>
        {props.mfa !== undefined && (
          <FieldRow>
            <Col span={8}>Two Factor Authentication (MFA)</Col>
            <Col style={{ fontWeight: 'bold' }} span={16}>
              <Button
                danger
                onClick={() =>
                  confirm({
                    icon: null,
                    title: 'Confirm to continue',
                    content:
                      'You would allow this member to access the workspace without Two-Factor Authentication. Are you sure to disable this step?',
                    onOk: () => props.disableMFA && props.disableMFA(user._id, user.display_name),
                    className: 'confirm-modal'
                  })
                }
                disabled={!props.mfa}
              >
                Disable
              </Button>
            </Col>
          </FieldRow>
        )}
        <FieldRow>
          <Col span={8}>Can create workspace</Col>
          <Col style={{ fontWeight: 'bold' }} span={16}>
            <Switch
              checked={user.can_create_workspace}
              onChange={(checked) => onWorkspaceCreationRightChange(user._id, checked)}
            />
          </Col>
        </FieldRow>
        <FieldRow>
          <Col span={8}>All access</Col>
          <Col span={16}>
            <Space size={5} direction="vertical">
              <Switch checked={!!user.is_vpc_admin} onChange={onAllAccessToggle} loading={allAccessToggling} />
              <Typography.Paragraph style={{ color: '#B0B4BB', marginBottom: 5 }}>
                By turning this on, this user will be able to access and manage the VPC admin panel and all project in
                every workspace.
              </Typography.Paragraph>
            </Space>
          </Col>
        </FieldRow>
        {!env.EMAIL_ENABLE && user.can_change_password && (
          <FieldRow>
            <Col span={8}>Password</Col>
            <Col span={16}>
              {resetPasswordLink && <Input value={resetPasswordLink} readOnly disabled={resetPasswordLinkLoading} />}
              <Button
                type="default"
                htmlType="button"
                onClick={onResetPasswordButtonClick}
                loading={resetPasswordLinkLoading}
              >
                Generate a new reset password link
              </Button>
              {resetPasswordLinkError && <Alert type="error" message={resetPasswordLinkError} showIcon />}
            </Col>
          </FieldRow>
        )}
        <FieldRow>
          <Col span={8}>Delete user data</Col>
          <Col span={16}>
            <Space direction="vertical" size={5}>
              <Button
                type="primary"
                danger
                onClick={() => {
                  setRemoveUserConfirmationModalVisible(true)
                }}
                disabled={user.status === UserStatus.USER_STATUS_TERMINATE}
              >
                Delete this user from the server
              </Button>
              <Typography.Paragraph style={{ color: '#B0B4BB', marginBottom: 5 }}>
                User’s data including user name, email, and settings will be deleted from the server forever.
                The user will be unassigned from all tasks.
                This does not delete user-created tasks.
              </Typography.Paragraph>
            </Space>
          </Col>
        </FieldRow>
        <FieldRow>
          <WorkspaceTableForUser
            userWrapper={props.userWrapper}
            updateUserWorkspaceRole={updateUserWorkspaceRole}
            selectedRowKeys={selectedRowKeys}
            rowSelection={{
              selectedRowKeys,
              onChange: onSelectChange,
              preserveSelectedRowKeys: true
            }}
            onTrashButtonClick={(workspaceId: string) => {
              setRemovingWorkspaceIds([workspaceId])
            }}
            onRemoveWorkspaceButtonClick={() => {
              setRemovingWorkspaceIds(selectedRowKeys)
            }}
            onSelectAllButtonClick={(allMatchingWorkspaceIds: readonly string[]) => {
              setSelectedRowKeys((prev) => [
                ...prev,
                ...allMatchingWorkspaceIds.filter((userId) => !prev.includes(userId))
              ])
            }}
            onDeselectAllButtonClick={() => {
              setSelectedRowKeys([])
            }}
          />
        </FieldRow>
      </Card>
      {renderModal()}
      {renderRemoveUserConfirmationModal()}
      {removingWorkspaceIds.length > 0 && (
        <UserInWorkspaceRemovalModal
          userId={props.userWrapper.user._id}
          workspaceIds={removingWorkspaceIds}
          onClose={() => {
            setRemovingWorkspaceIds([])
          }}
          onRemoveSuccuss={() => {
            setRemovingWorkspaceIds([])
            setSelectedRowKeys([])
          }}
        />
      )}
    </div>
  )
}

const workspaceTitleKey = 'display_name'

function WorkspaceTableForUser(props: {
  readonly userWrapper: IUserWrapper
  readonly selectedRowKeys: string[]
  readonly updateUserWorkspaceRole: (userId: string, workspaceId: string, role: 'ADMIN' | 'MEMBER') => Promise<void>
  readonly rowSelection?: TableProps<IWorkspaceWrapper>['rowSelection']
  onTrashButtonClick: (workspaceId: string) => void
  onRemoveWorkspaceButtonClick: () => void
  onDeselectAllButtonClick: () => void
  onSelectAllButtonClick: (allMatchingWorkspaceIds: readonly string[]) => void
}) {
  const pageChunk = 20
  const [pageIndex, setPageIndex] = useState(1)

  const [searchWorkspaceName, setSearchWorkspaceName] = useState('')

  const { workspaceWrappers, loading, totalMatchingWorkspaceCount, allMatchingWorkspaceIds } = useWorkspaces({
    userId: props.userWrapper.user._id,
    display_name: searchWorkspaceName,
    pageIndex,
    pageChunk
  })
  const env = useEnv()

  const columns: ColumnsType<IWorkspaceWrapper> = [
    {
      key: 'action',
      render: (_, { workspace }) => (
        <Button
          type="default"
          icon={<DeleteOutlined />}
          danger
          onClick={() => props.onTrashButtonClick(String(workspace._id))}
        />
      ),
      title: '',
      width: 8 + 32 + 8
    },
    {
      key: workspaceTitleKey,
      title: 'Workspace name',
      filterDropdown: TableColumnSearch,
      filterIcon: <SearchOutlined />,
      render: (_, { workspace }) => (
        <a href={`${env.ENTERPRISE_HOST}/${workspace.name}`} target="_blank" rel="noopener noreferrer">
          {workspace.display_name || workspace.name}
        </a>
      )
    },
    {
      title: 'Role',
      key: 'role',
      render: (_, { workspace }) => {
        const role = props.userWrapper.roles[String(workspace._id)]

        if (role === 'OWNER' || role === 'GUEST') {
          return props.userWrapper.roles[String(workspace._id)]
        }

        return (
          <Dropdown
            menu={{
              items: [
                {
                  label: 'ADMIN',
                  key: 'ADMIN'
                },
                {
                  label: 'MEMBER',
                  key: 'MEMBER'
                }
              ],
              onClick: async (e) => {
                if (e.key === role) {
                  return
                }

                await props.updateUserWorkspaceRole(
                  String(props.userWrapper.user._id),
                  String(workspace._id),
                  e.key as 'ADMIN' | 'MEMBER'
                )
              }
            }}
          >
            <Button>
              <Space>
                {role === 'ADMIN' ? 'Admin' : 'Member'}
                <DownOutlined />
              </Space>
            </Button>
          </Dropdown>
        )
      }
    }
  ]

  const hasSelected = props.selectedRowKeys.length > 0

  return (
    <div style={{ width: '100%' }}>
      <Space direction="horizontal" size={10} style={{ marginBottom: 24 }}>
        <span>{`${props.selectedRowKeys.length} workspaces selected`}</span>
        <div style={{ width: '1px', height: '32px', background: '#D9D9D9' }} />
        {allMatchingWorkspaceIds && (
          <Button
            onClick={() => props.onSelectAllButtonClick(allMatchingWorkspaceIds)}
            disabled={props.selectedRowKeys.length === allMatchingWorkspaceIds.length}
          >
            Select all
          </Button>
        )}
        <Button onClick={props.onDeselectAllButtonClick} disabled={props.selectedRowKeys.length === 0}>
          Deselect all
        </Button>
        <Button type="primary" danger disabled={!hasSelected} onClick={props.onRemoveWorkspaceButtonClick}>
          Remove workspace
        </Button>
      </Space>
      <ConfigProvider
        theme={{
          token: {
            colorPrimaryBg: taskworldColors.tableRowSelectedBg,
            colorPrimaryBgHover: taskworldColors.tableRowSelectedBgHover
          }
        }}
      >
        <Table
          rowKey={(record) => String(record.workspace._id)}
          dataSource={workspaceWrappers}
          columns={columns}
          pagination={{
            current: pageIndex,
            total: totalMatchingWorkspaceCount,
            defaultPageSize: pageChunk,
            showSizeChanger: false
          }}
          onChange={(pagination, filters) => {
            setPageIndex(pagination.current || 1)
            setSearchWorkspaceName(String(filters[workspaceTitleKey] || ''))
          }}
          size="small"
          loading={loading}
          rowSelection={props.rowSelection}
        />
      </ConfigProvider>
    </div>
  )
}
