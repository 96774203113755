import CloseCircleOutlined from '@ant-design/icons/CloseCircleOutlined'
import DeleteOutlined from '@ant-design/icons/DeleteOutlined'
import MenuOutlined from '@ant-design/icons/MenuOutlined'
import UserOutlined from '@ant-design/icons/UserOutlined'
import Button from 'antd/es/button'
import Dropdown from 'antd/es/dropdown'
import Menu from 'antd/es/menu'
import _ from 'lodash'
import React, { useState } from 'react'
import { IWorkspaceWrapper } from '../types'
import WorkspaceDeletionCancellation from './WorkspaceDeletionCancellation'
import WorkspaceDeletionModal from './WorkspaceDeletionModal'

export default function WorkspaceActions(props: {
  readonly workspaceWrapper: IWorkspaceWrapper
  readonly goToWorkspaceDetailPage: (workspaceWrapper: IWorkspaceWrapper) => void
  readonly deleteWorkspace: (workspaceId: string) => Promise<void>
  readonly cancelDeletingWorkspace: (workspaceId: string) => Promise<void>
}) {
  const workspaceId = String(props.workspaceWrapper.workspace._id)

  const [menuVisible, setMenuVisible] = useState(false)

  const [deleting, setDeleting] = useState(false)

  const [cancelling, setCancelling] = useState(false)

  return (
    <React.Fragment>
      <Dropdown
        overlay={
          <Menu>
            <Menu.Item
              icon={<UserOutlined />}
              onClick={() => {
                setMenuVisible(false)
                props.goToWorkspaceDetailPage(props.workspaceWrapper)
              }}
            >
              View users in this workspace
            </Menu.Item>
            {props.workspaceWrapper.status === 'active' ? (
              <Menu.Item
                icon={<DeleteOutlined />}
                danger
                onClick={() => {
                  setMenuVisible(false)
                  setDeleting(true)
                }}
              >
                Delete workspace
              </Menu.Item>
            ) : (
              <Menu.Item
                icon={<CloseCircleOutlined />}
                onClick={() => {
                  setMenuVisible(false)
                  setCancelling(true)
                }}
              >
                Cancel workspace deletion
              </Menu.Item>
            )}
          </Menu>
        }
        trigger={['click']}
        placement="bottomLeft"
        visible={menuVisible}
        onVisibleChange={setMenuVisible}
      >
        <Button type="default" icon={<MenuOutlined />} />
      </Dropdown>
      {deleting && (
        <WorkspaceDeletionModal
          workspace={props.workspaceWrapper.workspace}
          onConfirm={() => {
            return props.deleteWorkspace(workspaceId)
          }}
          onClose={() => {
            setDeleting(false)
          }}
        />
      )}
      {cancelling && (
        <WorkspaceDeletionCancellation
          workspace={props.workspaceWrapper.workspace}
          onConfirm={() => {
            return props.cancelDeletingWorkspace(workspaceId)
          }}
          onClose={() => {
            setCancelling(false)
          }}
        />
      )}
    </React.Fragment>
  )
}
