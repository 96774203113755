import React from 'react'
import Spin from 'antd/es/spin'

export function LoadingStatePage() {
  return (
    <div>
      <div style={{ display: 'inline-block', marginRight: '10px' }}>
        <Spin />
      </div>
        Loading...
    </div>
  )
}
