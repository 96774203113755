import SearchOutlined from '@ant-design/icons/SearchOutlined'
import Table from 'antd/es/table'
import formatDistance from 'date-fns/formatDistance'
import _ from 'lodash'
import React from 'react'

import { IWorkspaceWrapper } from '../types'
import TableColumnSearch from '../components/TableColumnSearch'
import WorkspaceActions from '../components/WorkspaceActions'
import usePersistedTableStateInfo from '../hooks/usePersistedTableStateInfo'
import useWorkspaces from '../hooks/useWorkspaces'

export default function WorkspacesTable(props: {
  readonly env: any
  readonly goToWorkspaceDetailPage: (workspaceWrapper: IWorkspaceWrapper) => void
}) {
  const { columns, pageIndex, onChange } = usePersistedTableStateInfo<IWorkspaceWrapper>([
    {
      key: 'action',
      render: (_, workspaceWrapper) => (
        <WorkspaceActions
          workspaceWrapper={workspaceWrapper}
          goToWorkspaceDetailPage={props.goToWorkspaceDetailPage}
          deleteWorkspace={deleteWorkspace}
          cancelDeletingWorkspace={restoreWorkspace}
        />
      ),
      width: 8 + 32 + 8 // 8 is from <Table size="small">
    },
    {
      key: 'display_name',
      title: 'Workspace name',
      filterDropdown: TableColumnSearch,
      filterIcon: <SearchOutlined />,
      sorter: true,
      render(_, { workspace }) {
        const workspaceSettingUrl = `${props.env.ENTERPRISE_HOST}/${workspace.name}/#/workspace/settings`

        return (
          <a href={workspaceSettingUrl} target="_blank" rel="noopener noreferrer">
            {workspace.display_name || workspace.name}
          </a>
        )
      }
    },
    {
      key: 'usersCount',
      title: 'Total user count',
      render: (_, { usersCount }) => usersCount
    },
    {
      key: 'activeUserCount',
      title: 'Active user count',
      render: (_, { activeUserCount }) => activeUserCount
    },
    {
      key: 'status',
      title: 'Deletion status',
      render: (_, workspaceWrapper) => {
        if (workspaceWrapper.status === 'active') {
          return 'Active'
        }

        const countdown = formatDistance(
          new Date(workspaceWrapper.workspace.metadata.schedule_deleted_date),
          new Date()
        )

        return `Pending delete (${countdown} left)`
      },
      filters: [
        {
          text: 'Active',
          value: 'active'
        },
        {
          text: 'Pending delete',
          value: 'pending_delete'
        }
      ],
      filterMultiple: false
    }
  ])

  const getFilteredValue = (key: string) => columns.find((column) => column.key === key)?.filteredValue || undefined

  const filteredStatus = (getFilteredValue('status') || [])[0] || undefined

  const sortColumn = columns.find((column) => column.sortOrder)

  const pageChunk = Math.max(
    Math.floor(
      (window.innerHeight - 60 /* Sum of <MainLayout> margin */ - 56 /* Pagination height */ - 70) /* Footer height */ /
        50 /* Row height */
    ) - 1 /* Column header */,
    5
  )

  const { workspaceWrappers, loading, totalMatchingWorkspaceCount, deleteWorkspace, restoreWorkspace } = useWorkspaces({
    display_name: getFilteredValue('display_name')?.toString(),
    status: (() => {
      if (filteredStatus === undefined) {
        return undefined
      }

      return filteredStatus === 'active' ? 'active' : 'pending_delete'
    })(),
    pageIndex,
    pageChunk,
    sortColumn: sortColumn ? String(sortColumn.key) : undefined,
    sortOrder: sortColumn?.sortOrder
  })

  return (
    <Table
      rowKey={(row) => String(row.workspace._id)}
      columns={columns}
      dataSource={workspaceWrappers}
      loading={loading}
      size="small"
      pagination={{
        current: pageIndex,
        total: totalMatchingWorkspaceCount,
        defaultPageSize: pageChunk,
        showSizeChanger: false
      }}
      onChange={onChange}
    />
  )
}
