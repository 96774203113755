import CloseCircleTwoTone from '@ant-design/icons/CloseCircleTwoTone'
import DownOutlined from '@ant-design/icons/DownOutlined'
import ExclamationCircleTwoTone from '@ant-design/icons/ExclamationCircleTwoTone'
import SendOutlined from '@ant-design/icons/SendOutlined'
import UploadOutlined from '@ant-design/icons/UploadOutlined'
import WarningTwoTone from '@ant-design/icons/WarningTwoTone'

import Upload from 'antd/es/upload'
import Button from 'antd/es/button'
import Dropdown from 'antd/es/dropdown'
import Menu from 'antd/es/menu'
import Modal from 'antd/es/modal'
import Space from 'antd/es/space'
import Link from 'antd/es/typography/Link'
import Text from 'antd/es/typography/Text'
import _ from 'lodash'
import React, { useEffect, useState } from 'react'
import { IWorkspaceWrapper, IPrivateServerInfo, IApiInviteUsersResult } from '../types'
import * as ApiConnector from '../apiConnector'
import useWorkspaces from '../hooks/useWorkspaces'
import EmailTagField, { emailSeparators } from './EmailTagField'
import { fetchLicense as fetchLicenses } from '../services/LicenseServices'
import UserOutlined from '@ant-design/icons/UserOutlined'
import { taskworldColors } from '../colors'

export default function InvitationModal(props: {
  readonly workspaceWrapper: IWorkspaceWrapper
  readonly onClose: () => void
}) {
  const [emails, setEmails] = useState<readonly string[]>([])
  const [validating, setValidating] = useState(false)
  const [sending, setSending] = useState(false)
  const [validationStats, setValidationStats] = useState<IApiInviteUsersResult>({
    invalidEmails: [],
    activeUserEmails: [],
    awaitingConfirmationUsersEmails: [],
    invitedUserEmails: []
  })
  const [role, setRole] = useState<'member' | 'admin'>('member')
  const { inviteUsersToWorkspace } = useWorkspaces({ shouldFetchData: false })
  const [license, setLicense] = useState<IPrivateServerInfo | null>(null)

  const fetchLicense = async () => {
    try {
      const license = await fetchLicenses()
      setLicense(license)
    } catch (error) {
      setLicense(null)
    }
  }

  useEffect(() => {
    fetchLicense()
  }, [])

  useEffect(() => {
    if (emails.length === 0) {
      setValidationStats({
        invalidEmails: [],
        activeUserEmails: [],
        awaitingConfirmationUsersEmails: [],
        invitedUserEmails: []
      })

      return
    }

    ;(async () => {
      setValidating(true)

      const response = await sendInvitationInternal({
        dryRun: true,
        emailsToBeInvited: emails
      })

      if (response) {
        setValidationStats((validationStats) => ({
          activeUserEmails: response.activeUserEmails,
          invalidEmails: response.invalidEmails,
          invitedUserEmails: _.union(validationStats.invitedUserEmails, response.invitedUserEmails),
          awaitingConfirmationUsersEmails: _.difference(
            response.awaitingConfirmationUsersEmails,
            response.invitedUserEmails
          )
        }))
      }

      setValidating(false)
    })()
  }, [emails])

  if (!license) {
    return null
  }

  const sendInvitationInternal = async ({
    dryRun,
    emailsToBeInvited
  }: {
    readonly dryRun: boolean
    readonly emailsToBeInvited: readonly string[]
  }): Promise<IApiInviteUsersResult | void> => {
    // Do not send invitation to whom are already sent
    const unInvitedEmails: readonly string[] = _.difference(emailsToBeInvited, validationStats.invitedUserEmails)

    if (unInvitedEmails.length === 0) {
      Modal.info({ content: 'No email left to send invitation.', centered: true })

      return
    }

    try {
      const response = await inviteUsersToWorkspace({
        dryRun,
        role,
        emails: unInvitedEmails,
        workspaceId: String(props.workspaceWrapper.workspace._id)
      })

      return response
    } catch (error) {
      Modal.error({
        title: 'Sending invitation failed!',
        content: ApiConnector.getApiErrorMessage(error),
        centered: true,
        okButtonProps: {
          style: { backgroundColor: taskworldColors.primary }
        }
      })
    }
  }

  const onEmailsChange = (newEmails: readonly string[]) => {
    if (_.isEqual(emails, newEmails)) return
    setEmails(_.uniq(newEmails))
  }

  const uploadCSV = ({ file }: any) => {
    const reader = new FileReader()
    reader.readAsText(file)
    reader.onload = () => {
      const emailsData = reader.result as string
      const emailsFromCSV = _.chain(emailsData.split(emailSeparators)).compact().uniq().value()
      onEmailsChange([...emails, ...emailsFromCSV])
    }
  }

  const sendInvitation = async () => {
    setSending(true)

    const response = await sendInvitationInternal({ dryRun: false, emailsToBeInvited: emails })
    Modal.success({ content: 'Invitation sent successfully', centered: true })
    await fetchLicense()
    if (response && response.invitedUserEmails.length > 0) {
      setValidationStats((validationStats) => ({
        ...response,
        invitedUserEmails: _.union(validationStats.invitedUserEmails, response.invitedUserEmails),
        awaitingConfirmationUsersEmails: _.difference(
          response.awaitingConfirmationUsersEmails,
          response.invitedUserEmails
        )
      }))
    }

    setSending(false)
  }

  const resendInvitation = async () => {
    if (validationStats.awaitingConfirmationUsersEmails.length > 0) {
      setSending(true)

      const response = await sendInvitationInternal({
        dryRun: false,
        emailsToBeInvited: validationStats.awaitingConfirmationUsersEmails
      })
      if (response && response.invitedUserEmails.length > 0) {
        setValidationStats((validationStats) => ({
          ...validationStats,
          awaitingConfirmationUsersEmails: [],
          invitedUserEmails: _.union(validationStats.invitedUserEmails, response.invitedUserEmails)
        }))
        Modal.success({ content: 'Invitation re-sent successfully', centered: true })
      }

      setSending(false)
    }
  }

  const onRoleSelect = ({ key }: any) => {
    setRole(key)
  }

  const removeErrors = () => {
    if (validationStats.invalidEmails.length) {
      setEmails(emails.filter((email: string) => !validationStats.invalidEmails.includes(email)))
      setValidationStats((validationResponse) => ({
        ...validationResponse,
        invalidEmails: []
      }))
    }
  }

  const removeDuplicates = () => {
    if (validationStats.activeUserEmails.length > 0) {
      setEmails(emails.filter((email: string) => !validationStats.activeUserEmails.includes(email)))
      setValidationStats((validationResponse) => ({
        ...validationResponse,
        activeUserEmails: []
      }))
    }
  }

  const removePending = () => {
    if (validationStats.awaitingConfirmationUsersEmails.length > 0) {
      setEmails(emails.filter((email: string) => !validationStats.awaitingConfirmationUsersEmails.includes(email)))
      setValidationStats((validationResponse) => ({
        ...validationResponse,
        awaitingConfirmationUsersEmails: []
      }))
    }
  }

  const renderErrorSummary = () => {
    return (
      <div style={{ minHeight: 22 /* Spare a line of summary to avoid jumpy modal */ }}>
        {validationStats.invalidEmails.length > 0 && (
          <div>
            <Text type="danger">
              <CloseCircleTwoTone twoToneColor="red" style={{ marginRight: 4 }} />
              {validationStats.invalidEmails.length} invalid email
              {validationStats.invalidEmails.length === 1 ? '' : 's'}.
              <Link onClick={removeErrors} style={{ marginLeft: 4 }} disabled={sending}>
                Remove all invalid emails
              </Link>
            </Text>
          </div>
        )}
        {validationStats.activeUserEmails.length > 0 && (
          <div>
            <Text type="danger">
              <WarningTwoTone twoToneColor="red" style={{ marginRight: 4 }} />
              {validationStats.activeUserEmails.length} existing user
              {validationStats.activeUserEmails.length === 1 ? '' : 's'}.
              <Link onClick={removeDuplicates} style={{ marginLeft: 4 }} disabled={sending}>
                Remove duplicates
              </Link>
            </Text>
          </div>
        )}
        {validationStats.awaitingConfirmationUsersEmails.length > 0 && (
          <div>
            <Text>
              <ExclamationCircleTwoTone twoToneColor="orange" style={{ marginRight: 4 }} />
              {validationStats.awaitingConfirmationUsersEmails.length} pending on accepting the invitation.
              <Link onClick={resendInvitation} style={{ marginLeft: 4 }} disabled={sending}>
                Resend invitations
              </Link>
              <span style={{ marginLeft: 4 }}>or</span>
              <Link onClick={removePending} style={{ marginLeft: 4 }} disabled={sending}>
                Remove
              </Link>
            </Text>
          </div>
        )}
      </div>
    )
  }

  const roleSelectionOptions = (
    <Menu onClick={onRoleSelect}>
      <Menu.Item key={'member'}>Member</Menu.Item>
      <Menu.Item key={'admin'}>Admin</Menu.Item>
    </Menu>
  )

  const excludedEmails = [
    ...validationStats.activeUserEmails,
    ...validationStats.invalidEmails,
    ...validationStats.awaitingConfirmationUsersEmails,
    ...validationStats.invitedUserEmails
  ]

  const validEmails = emails.filter((email) => !excludedEmails.includes(email))
  const totalUsersCount = license.activeUser + validEmails.length
  const exceedUserLimit = totalUsersCount > license.userLimit

  return (
    <Modal
      title="Invite users"
      visible
      centered
      maskClosable={false}
      onCancel={props.onClose}
      footer={
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <Upload accept=".xlsx, .xls, .csv" onChange={uploadCSV} beforeUpload={() => false} maxCount={1} fileList={[]}>
            <Button icon={<UploadOutlined />} title="Select a file which contains a list of emails separated by comma.">
              Import emails from CSV
            </Button>
          </Upload>
          <Space size={10}>
            <Dropdown overlay={roleSelectionOptions}>
              <Button>
                {_.capitalize(role)} <DownOutlined />
              </Button>
            </Dropdown>
            <Button
              type="primary"
              icon={<SendOutlined />}
              loading={sending}
              disabled={
                validating ||
                emails.length === 0 ||
                validationStats.awaitingConfirmationUsersEmails.length > 0 ||
                validationStats.invalidEmails.length > 0 ||
                validationStats.activeUserEmails.length > 0 ||
                exceedUserLimit
              }
              onClick={sendInvitation}
            >
              Send
            </Button>
          </Space>
        </div>
      }
    >
      <Space direction="vertical" size="middle" style={{ width: '100%' }}>
        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
          {license && (
            <Space size={6}>
              <UserOutlined style={{ strokeWidth: '300' }} />
              <div style={{ fontWeight: 500 }}>
                <span style={{ color: exceedUserLimit ? 'red' : undefined }}>{totalUsersCount}</span>
                {`/${license.userLimit}`}
              </div>
            </Space>
          )}
        </div>
        <EmailTagField
          emails={emails}
          onChange={onEmailsChange}
          validationResponse={validationStats}
          workspaceWrapper={props.workspaceWrapper}
          validating={validating}
        />
        {renderErrorSummary()}
      </Space>
    </Modal>
  )
}
