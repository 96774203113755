import DownloadOutlined from '@ant-design/icons/DownloadOutlined'
import Button from 'antd/es/button'
import message from 'antd/es/message'
import * as FileSaver from 'file-saver'
import React, { useEffect, useState } from 'react'
import csv from 'react-csv-downloader/dist/lib/csv'
import useEnv from '../hooks/useEnv'
import useWorkspaces from '../hooks/useWorkspaces'

export default function WorkspaceExportButton() {
  const [loading, setLoading] = useState(false)

  return (
    <React.Fragment>
      <Button
        type="primary"
        icon={<DownloadOutlined />}
        onClick={() => {
          setLoading(true)
        }}
        loading={loading}
      >
        Download Workspace Report
      </Button>
      {loading && <Downloader onDone={() => { setLoading(false) }} />}
    </React.Fragment>
  )
}

function Downloader(props: {
  readonly onDone: () => void
}) {
  const { workspaceWrappers, loading } = useWorkspaces()
  const env = useEnv()

  useEffect(() => {
    if (!loading) {
      const rows = workspaceWrappers.map((workspaceWrapper) => {
        return {
          url: `${env.ENTERPRISE_HOST}/${workspaceWrapper.workspace.name}/#/workspace/settings`,
          title: workspaceWrapper.workspace.display_name || workspaceWrapper.workspace.name,
          totalUserCount: String(workspaceWrapper.usersCount),
          activeUserCount: String(workspaceWrapper.activeUserCount),
          status: workspaceWrapper.status === 'active' ? 'Active' : 'Pending delete',
        }
      })

      const columns: readonly { readonly id: keyof Unpacked<typeof rows>; readonly displayName: string }[] = [
        {
          id: 'url',
          displayName: 'Workspace URL'
        },
        {
          id: 'title',
          displayName: 'Workspace name'
        },
        {
          id: 'totalUserCount',
          displayName: 'Total user count'
        },
        {
          id: 'activeUserCount',
          displayName: 'Active user count'
        },
        {
          id: 'status',
          displayName: 'Deletion status'
        }
      ]

      const fileName: string = `taskworld-workspace-report-${new Date().toISOString()}`

      csv({ columns: columns as any, datas: rows }).then((fileText) => {
        props.onDone()

        // Copied from https://github.com/dolezel/react-csv-downloader/blob/c7cd43271908f721ac25f0aed999bd09996a61f6/src/index.tsx
        const blob = new Blob([fileText as string], { type: 'text/csv;charset=utf-8' })
        FileSaver.saveAs(blob, fileName + '.csv', { autoBom: true })
      }).catch(() => {
        message.error('Could not generate CSV file. Please contact support@taskworld.com')
      })
    }
  }, [loading])

  return null
}
