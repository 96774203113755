import React, { useContext } from 'react'
import useSWR from 'swr'
import { getEnv } from '../services/EnvServices'

const EnvContext = React.createContext<{ [key: string]: string }>({})

export default function useEnv() {
  const env = useContext(EnvContext)

  return env
}

export function EnvFetcher(props: { children: React.ReactNode }) {
  const { data, error } = useSWR('env', async () => {
    const response = await getEnv()

    return response
  }, { revalidateOnFocus: false })

  if (error) {
    throw error
  }

  if (data === undefined) {
    return null
  }

  return <EnvContext.Provider value={data}>{props.children}</EnvContext.Provider>
}
