import DeleteOutlined from '@ant-design/icons/DeleteOutlined'
import SafetyOutlined from '@ant-design/icons/SafetyOutlined'
import MenuOutlined from '@ant-design/icons/MenuOutlined'
import UserOutlined from '@ant-design/icons/UserOutlined'
import Button from 'antd/es/button'
import Dropdown from 'antd/es/dropdown'
import Menu from 'antd/es/menu'
import _ from 'lodash'
import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { isUserRemovedByProfiles } from '../utils'
import { IWorkspaceWrapper } from '../types/WorkspaceTypes'
import { routes } from '../routes'
import UserInWorkspaceRemovalModal from './UserInWorkspaceRemovalModal'

export default function UserActions(props: {
  readonly userId: string
  readonly workspaceWrapper?: IWorkspaceWrapper
  readonly mfaEnabled?: boolean
  readonly onDisableMFAClick?: () => void | Promise<void> | any
}) {
  const [menuVisible, setMenuVisible] = useState(false)

  const [removingUserId, setRemovingUserId] = useState<string | null>(null)

  const history = useHistory()

  const goToUserDetailPage = () => {
    history.push(routes['/users/:userId'].makePath({ userId: props.userId }))
  }

  return (
    <React.Fragment>
      <Dropdown
        overlay={
          <Menu>
            <Menu.Item
              icon={<UserOutlined />}
              onClick={goToUserDetailPage}
            >
              View user details
            </Menu.Item>
            {
              props.workspaceWrapper &&
              isUserRemovedByProfiles(props.workspaceWrapper.workspace.member_profiles || [], props.userId) && (
                <Menu.Item
                  icon={<DeleteOutlined />}
                  danger
                  onClick={() => {
                    setMenuVisible(false)
                    setRemovingUserId(props.userId)
                  }}
                >
                  Remove this user from the workspace
                </Menu.Item>
              )
            }
            {
              props.mfaEnabled && (
                <Menu.Item
                  icon={<SafetyOutlined />}
                  onClick={() => {
                    setMenuVisible(false)
                    props.onDisableMFAClick && props.onDisableMFAClick()
                  }}
                >
                  Disable 2FA
                </Menu.Item>
              )
            }
          </Menu>
        }
        trigger={['click']}
        placement="bottomLeft"
        visible={menuVisible}
        onVisibleChange={setMenuVisible}
      >
        <Button type="default" icon={<MenuOutlined />} />
      </Dropdown>
      {removingUserId && props.workspaceWrapper && (
        <UserInWorkspaceRemovalModal
          userId={removingUserId}
          workspaceIds={[String(props.workspaceWrapper.workspace._id)]}
          onClose={() => {
            setRemovingUserId(null)
          }}
        />
      )}
    </React.Fragment>
  )
}
