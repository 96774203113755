import Input from 'antd/es/input'
import Modal from 'antd/es/modal'
import Space from 'antd/es/space'
import Text from 'antd/es/typography/Text'
import _ from 'lodash'
import React, { useMemo, useRef, useState } from 'react'
import { IWorkspace } from '../types'
import * as ApiConnector from '../apiConnector'
import { getCurrentUserEmail } from '../services/AuthServices'

type Props = {
  readonly workspace: Pick<IWorkspace, 'display_name'>
  readonly onConfirm: () => Promise<void>
  readonly onClose: () => void
}

export default function WorkspaceDeletionModal(props: Props) {
  const [email, setEmail] = useState('')
  const [loading, setLoading] = useState(false)
  const [errorEmailMismatched, setErrorEmailMismatched] = useState(false)
  const inputRef = useRef<Input>(null)

  const readyToDelete = useMemo(() => {
    const vpcAdminEmail = getCurrentUserEmail()

    if (!vpcAdminEmail) {
      return false
    }
    return vpcAdminEmail.localeCompare(email) === 0
  }, [email])

  const focusEmailInput = () => {
    inputRef.current && inputRef.current.focus()
  }

  const onDeleteButtonClick = async () => {
    if (!readyToDelete) {
      focusEmailInput()
      setErrorEmailMismatched(true)
      return
    }

    try {
      setLoading(true)
      await props.onConfirm()
      props.onClose()
    } catch (error) {
      Modal.error({
        title: 'Delete Workspace Failed!',
        content: ApiConnector.getApiErrorMessage(error),
        centered: true
      })
    } finally {
      setLoading(false)
      focusEmailInput()
    }
  }

  return (
    <Modal
      visible
      title="Delete Workspace"
      okType="danger"
      okText="Delete"
      cancelText="Cancel"
      centered
      confirmLoading={loading}
      closable={!loading}
      maskClosable={!loading}
      cancelButtonProps={{ disabled: loading }}
      onOk={onDeleteButtonClick}
      onCancel={props.onClose}
    >
      <Space direction="vertical" size="small">
        <div>
          Do you want to delete workspace <b>{props.workspace.display_name || props.workspace.name}</b>?
        </div>
        <div>When you delete a workspace, these happen:</div>
        <ul style={{ margin: 0 }}>
          <li>Your subscription stops</li>
          <li>All members lose access to the workspace data</li>
          <li>Workspace admins will be notified</li>
          <li>Workspace will be deleted permanently in 14 days</li>
          <li>Workspace admins can cancel the deletion within 14 days</li>
        </ul>
        <div>To proceed, enter your email to confirm</div>
        <Input
          autoFocus
          ref={inputRef}
          type="email"
          value={email}
          disabled={loading}
          onChange={(e) => {
            if (errorEmailMismatched) {
              setErrorEmailMismatched(false)
            }
            setEmail(e.target.value)
          }}
          onPressEnter={onDeleteButtonClick}
        />
        {errorEmailMismatched && <Text type="danger">Email is incorrect.</Text>}
      </Space>
    </Modal>
  )
}
