import * as ApiConnector from '../apiConnector'
import useEnv from '../hooks/useEnv'

export function isAuthen() {
  return !!localStorage.getItem('TW_ACCESS_TOKEN')
}

export function getAccessToken() {
  return localStorage.getItem('TW_ACCESS_TOKEN')
}

export function getCurrentUserEmail() {
  return localStorage.getItem('TW_USER_EMAIL')
}

export function isCurrentUserVPCAdmin() {
  const env = useEnv()
  const allowedVPcAdminEmails = env.VPC_ADMINS_ALLOW_INVITES ? env.VPC_ADMINS_ALLOW_INVITES.split(';') : []
  const ultimateAdminEmails = env.ULTIMATE_ADMIN_EMAILS ? env.ULTIMATE_ADMIN_EMAILS.split(';') : []
  const listOfAllowedVpcAdminEmails = allowedVPcAdminEmails.concat(ultimateAdminEmails)
  const currentUserEmail = getCurrentUserEmail()
  
  return listOfAllowedVpcAdminEmails.includes(currentUserEmail ?? '')
}

export function getAuthenticatedApiConnector(): ApiConnector.AuthenticatedApiConnector {
  const accessToken = getAccessToken()
  if (!accessToken) {
    throw new Error('Not authorized')
  }

  return ApiConnector.getAuthenticatedApiConnectorFromAccessToken(accessToken)
}
function saveUserInfo(token: string, email: string) {
  localStorage.setItem('TW_ACCESS_TOKEN', token)
  localStorage.setItem('TW_USER_EMAIL', email)
}

type LoginResultSuccess = {
  success: true
  token: string
}
type LoginResultFailed = {
  success: false
}

export type LoginResult = LoginResultSuccess | LoginResultFailed

export async function authenticate(email: string, password: string) {
  const apiConnector = ApiConnector.getApiConnector()
  const loginResult = await apiConnector.post<LoginResult>('login', {
    email,
    password
  })
  if (loginResult.success) {
    saveUserInfo(loginResult.token, email)
  }

  return loginResult
}

export function logout() {
  localStorage.removeItem('TW_ACCESS_TOKEN')
}
