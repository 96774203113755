import React from 'react'
import { Redirect, Route, RouteComponentProps } from 'react-router-dom'
import LicensePage from './pages/licenses/LicensePage'
import LoginPage from './pages/LoginPage'
import LogoutPage from './pages/LogoutPage'
import UserPage from './pages/UserPage'
import UsersPage from './pages/UsersPage'
import WorkspaceDetailPage from './pages/WorkspaceDetailPage'
import WorkspacesPage from './pages/WorkspacesPage'

export type RoutingPath = keyof typeof routes

export const routes = {
  '/users': createRoute({
    page: UsersPage,
    makePath: () => '/users'
  }),
  '/users/:userId': createRoute<{ readonly userId: string }>({
    page: UserPage,
    makePath: (params) => `/users/${params.userId}`
  }),
  '/workspaces': createRoute({
    page: WorkspacesPage,
    makePath: () => '/workspaces'
  }),
  '/workspaces/:workspaceId': createRoute<{ workspaceId: string }>({
    page: WorkspaceDetailPage,
    makePath: (params) => `/workspaces/${params.workspaceId}`
  }),
  '/license': createRoute({
    page: LicensePage,
    makePath: () => '/license'
  }),
  '/login': createRoute({
    page: LoginPage,
    makePath: () => '/login'
  }),
  '/logout': createRoute({
    page: LogoutPage,
    makePath: () => '/logout'
  })
}

function createRoute<T extends {} = {}>(options: {
  readonly page: React.ComponentType<RouteComponentProps<T>>
  readonly makePath: (params: T) => string
}) {
  return options
}

export function RouteInternal(props: { readonly path: RoutingPath }) {
  const route = routes[props.path]
  return <Route path={props.path} exact component={route.page} />
}

export function RedirectInternal(props: { to: RoutingPath }) {
  return <Redirect to={props.to} />
}
