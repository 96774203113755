import message from 'antd/es/message'
import Tabs, { TabsProps } from 'antd/es/tabs'
import { RadioChangeEvent } from 'antd/es/radio'
import _ from 'lodash'
import React, { useEffect, useState } from 'react'

import { IPrivateServerInfo, Invitation, WorkspaceCreator, ILicenseUpdateRequest } from '../../types'
import * as ApiConnector from '../../apiConnector'
import * as AuditServices from '../../services/AuditServices'
import * as AuthService from '../../services/AuthServices'
import * as LicenseServices from '../../services/LicenseServices'
import { Card, LoadingStatePage } from '../../uikit'
import DataTab from './DataTab'
import FeatureToggleTab from './FeatureToggleTab'
import LicenseTab from './LicenseTab'

type LicensePageProps = {
  readonly currentUserEmail: string
  readonly onFetchLicense: () => Promise<IPrivateServerInfo>
  readonly onUpdateGoogleReportEnabled: (isEnabled: boolean) => Promise<boolean>
  readonly onUpdateTimeTrackEnabled: (isEnabled: boolean) => Promise<boolean>
  readonly onAccountDeletionEnabled: (isEnabled: boolean) => Promise<boolean>
  readonly onTransferOwnershipEnabled: (isEnabled: boolean) => Promise<boolean>
  readonly onAllProjectAdminTransferEnabled: (isEnabled: boolean) => Promise<boolean>
  readonly onUserExportToggle: (enabled: boolean) => Promise<void>
  readonly onUpdateLicensePermission: (request: ILicenseUpdateRequest) => Promise<boolean>
  readonly onTriggerAuditDownload: (email: string, range: [Date, Date]) => Promise<boolean>
  readonly onTriggerMemberDownload: (email: string) => Promise<boolean>
  readonly onTriggerUserDownload: (email: string) => Promise<boolean>
}

export type DownloadingReport = null | 'audit' | 'member' | 'user'

export default () => {
  const currentUserEmail = AuthService.getCurrentUserEmail()

  if (!currentUserEmail) {
    return null
  }

  return (
    <LicensePage
      currentUserEmail={currentUserEmail}
      onFetchLicense={LicenseServices.fetchLicense}
      onUpdateTimeTrackEnabled={LicenseServices.updateTimeTrackingEnabled}
      onUpdateGoogleReportEnabled={LicenseServices.updateGoogleReportEnabled}
      onAccountDeletionEnabled={LicenseServices.updateAccountDeletionEnabled}
      onTransferOwnershipEnabled={LicenseServices.updateTransferOwnershipEnabled}
      onAllProjectAdminTransferEnabled={LicenseServices.updateAllProjectAdminTransferEnabled}
      onUserExportToggle={LicenseServices.updateUserExportEnabled}
      onUpdateLicensePermission={LicenseServices.updateLicensePermission}
      onTriggerAuditDownload={AuditServices.triggerAuditDownload}
      onTriggerMemberDownload={AuditServices.triggerMemberDownload}
      onTriggerUserDownload={AuditServices.triggerUserDownload}
    />
  )
}

function LicensePage(props: LicensePageProps) {
  const [errorMessage, setErrorMessage] = useState('')
  const [saving, setSaving] = useState(false)
  const [downloadingReport, setDownloadingReport] = useState<DownloadingReport>(null)
  const [license, setLicense] = useState<IPrivateServerInfo | undefined | null>(undefined)

  useEffect(() => {
    fetchLicense()
    if (localStorage.getItem('TW_DEBUG') === '1') {
      LicenseServices.fetchLicenseUsersInfo().then(console.log).catch(console.error)
    }
  }, [])

  const fetchLicense = async () => {
    try {
      const license = await LicenseServices.fetchLicense()
      setLicense(license)
    } catch (error) {
      setLicense(null)
      setErrorMessage(ApiConnector.getApiErrorMessage(error))
    }
  }

  const resetLoadingState = () => {
    setSaving(false)
    setDownloadingReport(null)
  }

  const updateLicenseState = (options: Partial<IPrivateServerInfo>) => {
    setLicense((license) => {
      if (!license) {
        return license
      }

      return {
        ...license,
        ...options
      }
    })
  }

  const onDownloadAudits = async (email: string, dateRange: [Date, Date]) => {
    setDownloadingReport('audit')
    try {
      await props.onTriggerAuditDownload(email, dateRange)
      message.info(`System is now gathering license. The audits report will be sent to ${email}.`)
    } catch (error) {
      message.error(`Error: ${(error as Error).message}`)
    }
    setDownloadingReport(null)
  }

  const onDownloadMembers = async (email: string) => {
    setDownloadingReport('member')
    try {
      await props.onTriggerMemberDownload(email)
      message.info(`System is now gathering data. The members report will be sent to ${email}.`)
    } catch (error) {
      message.error(`Error: ${(error as Error).message}`)
    }
    setDownloadingReport(null)
  }

  const onDownloadUsers = async (email: string) => {
    setDownloadingReport('user')
    try {
      await props.onTriggerUserDownload(email)
      message.info(`System is now gathering data. The users report will be sent to ${email}.`)
    } catch (error) {
      message.error(`Error: ${(error as Error).message}`)
    }
    setDownloadingReport(null)
  }

  const onUpdateProjectReportEnabled = async (checked: boolean) => {
    const success = await props.onUpdateGoogleReportEnabled(checked)
    if (success && license) {
      updateLicenseState({
        projectReportEnabled: checked
      })
      resetLoadingState()
    }
  }

  const onUpdateTimeTrackEnabled = async (checked: boolean) => {
    const success = await props.onUpdateTimeTrackEnabled(checked)
    if (success && license) {
      updateLicenseState({
        timeTrackingEnabled: checked
      })
      resetLoadingState()
    }
  }

  const onAccountDeletionEnabled = async (checked: boolean) => {
    const success = await props.onAccountDeletionEnabled(checked)
    if (success && license) {
      updateLicenseState({
        accountDeletionEnabled: checked
      })
      resetLoadingState()
    }
  }

  const onTransferOwnershipEnabled = async (checked: boolean) => {
    const success = await props.onTransferOwnershipEnabled(checked)
    if (success && license) {
      updateLicenseState({
        transferOwnershipEnabled: checked
      })
      resetLoadingState()
    }
  }

  const onAllProjectAdminTransferEnabled = async (checked: boolean) => {
    const success = await props.onAllProjectAdminTransferEnabled(checked)
    if (success && license) {
      updateLicenseState({
        forceProjectAdminTransfer: checked
      })
      resetLoadingState()
    }
  }

  const onUserExportToggle = async (enabled: boolean) => {
    await props.onUserExportToggle(enabled)
    if (license) {
      updateLicenseState({
        userExportEnabled: enabled
      })
      resetLoadingState()
    }
  }

  const onInviteChange = (e: RadioChangeEvent) => {
    if (!license) {
      return
    }
    const onlyWorkspaceAdminCanInvite = !!e.target.value
    updateLicenseState({
      onlyWorkspaceAdminCanInvite
    })
    resetLoadingState()
  }

  const onWorkspaceCreatorChange = (e: RadioChangeEvent) => {
    if (!license) {
      return
    }
    const allUsersCanCreateWorkspace = !!e.target.value
    updateLicenseState({
      allUsersCanCreateWorkspace
    })
    resetLoadingState()
  }

  const onSave = async () => {
    if (!license) {
      return
    }

    setSaving(true)

    try {
      const success = await props.onUpdateLicensePermission({
        invite: license.onlyWorkspaceAdminCanInvite
          ? Invitation.WORKSPACE_ADMINS
          : Invitation.EVERYONE,
        workspaceCreator: license.allUsersCanCreateWorkspace
          ? WorkspaceCreator.EVERYONE
          : WorkspaceCreator.SPECIFIC_USERS
      })
      if (!success) {
        throw Error('Unexpected Error')
      }
      message.success('Configuration saved')
    } catch (error) {
      message.error(`Error: ${(error as Error).message}`)
    }
    setSaving(false)
  }

  const renderError = () => {
    return (
      <div>
        There are some error when loading user license. Please refresh the page.
        {errorMessage ? ` Error: ${errorMessage}` : ''}
      </div>
    )
  }

  if (license === undefined) {
    return <LoadingStatePage />
  }
  if (license === null) {
    return renderError()
  }

  const items: TabsProps['items'] = [
    {
      key: '1',
      label: `License and settings`,
      children: (
        <LicenseTab
          expiryDate={license.expiryDate}
          userLimit={license.userLimit}
          activeUser={license.activeUser}
          activeGuest={license.activeGuest}
          superAdmins={license.superAdmins || ''}
          saving={saving}
          onlyWorkspaceAdminCanInvite={license.onlyWorkspaceAdminCanInvite}
          allUsersCanCreateWorkspace={license.allUsersCanCreateWorkspace}
          onSave={onSave}
          onInviteChange={onInviteChange}
          onWorkspaceCreatorChange={onWorkspaceCreatorChange}
        />
      )
    },
    {
      key: '2',
      label: `Feature Toggle`,
      children: (
        <FeatureToggleTab
          timeTrackingEnabled={license.timeTrackingEnabled}
          onUpdateTimeTrackEnabled={onUpdateTimeTrackEnabled}
          projectReportEnabled={license.projectReportEnabled}
          onUpdateProjectReportEnabled={onUpdateProjectReportEnabled}
          accountDeletionEnabled={license.accountDeletionEnabled}
          onAccountDeletionEnabled={onAccountDeletionEnabled}
          transferOwnershipEnabled={license.transferOwnershipEnabled}
          onTransferOwnershipEnabled={onTransferOwnershipEnabled}
          forceProjectAdminTransfer={license.forceProjectAdminTransfer}
          onAllProjectAdminTransferEnabled={onAllProjectAdminTransferEnabled}
          userExportEnabled={license.userExportEnabled}
          onUserExportToggle={onUserExportToggle}
        />
      )
    },
    {
      key: '3',
      label: `Data`,
      children: (
        <DataTab
          defaultEmail={props.currentUserEmail}
          onDownloadAuditReport={onDownloadAudits}
          onDownloadMemberReport={onDownloadMembers}
          onDownloadUserReport={onDownloadUsers}
          downloadingReport={downloadingReport}
        />
      )
    }
  ]

  return (
    <div style={{ position: 'relative' }}>
      <h1>Private server info</h1>
      <Card>
        <Tabs defaultActiveKey="1" items={items} />
      </Card>
    </div>
  )
}
