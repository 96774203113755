import { FetchState } from './FetchState'

type DataLoadStateSuccess<T> = {
  fetchState: FetchState.Loaded
  data: T
}

type DataLoadStateNotLoaded = {
  fetchState: FetchState.Failed | FetchState.Loading | FetchState.NotLoaded
  errorMessage: string
}

export type DataLoadState<T> = DataLoadStateSuccess<T> | DataLoadStateNotLoaded
