import _ from 'lodash'
import React from 'react'
import { useHistory } from 'react-router-dom'
import useEnv from '../hooks/useEnv'
import { routes } from '../routes'
import WorkspacesTable from './WorkspacesTable'

export default React.memo(() => {
  const env = useEnv()
  const history = useHistory()

  return (
    <WorkspacesTable
      env={env}
      goToWorkspaceDetailPage={(workspaceWrapper) => {
        history.push({
          pathname: routes['/workspaces/:workspaceId'].makePath({
            workspaceId: String(workspaceWrapper.workspace._id)
          }),
          state: { workspaceWrapper }
        })
      }}
    />
  )
})
