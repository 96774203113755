import { ObjectId } from "mongoose"

export enum TrialMemberInviteMode {
  ON_WHITELIST_DOMAIN = 'ON_WHITELIST_DOMAIN',
  ON_ANY_DOMAIN = 'ON_ANY_DOMAIN',
  OFF = 'OFF'
}

export interface IWorkspaceSnapshot {
  terminated_at: Date
}

export interface IWorkspaceStats {
  numProjects: number
  numTasks: number
  numComments: number
  numAttachments: number
}

export interface IWorkspaceMemberProfile {
  _id: string
  is_removed: Boolean
  awaiting_confirmation: boolean
}

export interface IFeatureOverrides {
  task_dep?: boolean
  retro_plan?: boolean
  all_tasks?: boolean
  analytic?: boolean
  timetracking?: boolean
  guest?: boolean
  dependency_completion_email?: boolean
  APPROVAL_ENABLE?: boolean
}

export interface IWorkspace {
  _id: ObjectId | string
  name: string
  display_name: string
  company_name: string
  owner_id: string
  members: string[]
  member_profiles: IWorkspaceMemberProfile[]
  admins: string[]
  configs: string[]
  created: Date
  updated: Date
  tags: string[]
  id_counter: number
  project_id_counter: number
  project_group_id_counter: number
  task_id_counter: number
  channel_id_counter: number
  is_deleted: boolean
  is_boilerplate: boolean
  photo: string
  feature_overrides: IFeatureOverrides
  payment_account_id: string
  expected_num_of_members: string
  allowed_email_domains_for_invitation: string[]
  email_inbound_name: string
  country: string
  industry: string
  company_size: string
  metadata: any
  stats: IWorkspaceStats
  stats_updated: Date
  snapshot?: IWorkspaceSnapshot
  tag: string
  free_trial_notified: boolean
  expired_free_trial_notified: boolean
  free_trial_offered: boolean
  is_vpc_test: boolean
  is_locked?: boolean
  storage_size: number
  organization_id?: string
  trial_member_invite_mode?: TrialMemberInviteMode
  trial_member_allowed_invite_domains?: string[]
}

export interface IWorkspaceWrapper {
  readonly workspace: IWorkspace
  readonly status: string
  readonly activeUserCount: number
  readonly usersCount: number
}

