import CheckCircleOutlined from '@ant-design/icons/CheckCircleOutlined'
import CloseCircleOutlined from '@ant-design/icons/CloseCircleOutlined'
import ExclamationCircleOutlined from '@ant-design/icons/ExclamationCircleOutlined'
import PlusOutlined from '@ant-design/icons/PlusOutlined'
import WarningOutlined from '@ant-design/icons/WarningOutlined'
import LoadingOutlined from '@ant-design/icons/LoadingOutlined'
import Input from 'antd/es/input'
import Tag from 'antd/es/tag'
import Tooltip from 'antd/es/tooltip'
import Spin from 'antd/es/spin'
import _ from 'lodash'
import React, { useRef, useState } from 'react'
import { IWorkspaceWrapper } from '../types/WorkspaceTypes'
import { IApiInviteUsersResult } from '../types'

export const emailSeparators = /[\s\t,;<>]/

enum EMAIL_TAG_COLORS {
  'red'= 'red',
  'orange'= 'orange',
  'green'= 'green'
}

// This is used to sort the email tags in the list of emails.
// Makes sure errors are at the top, then the warnings and success and the rest.
const SORT_PRIORITY_FOR_EMAILS_BASED_ON_TAG_COLOR = {
  [EMAIL_TAG_COLORS.red]: 1,
  [EMAIL_TAG_COLORS.orange]: 2,
  [EMAIL_TAG_COLORS.green]: 3,
  'default': 4
}


export default function EmailTagField(props: {
  readonly emails: readonly string[]
  readonly onChange: (emails: string[]) => void
  readonly validationResponse: IApiInviteUsersResult | undefined
  readonly workspaceWrapper: IWorkspaceWrapper
  readonly validating?: boolean
}) {
  const [inputValue, setInputValue] = useState('')
  const inputRef = useRef<Input | null>(null)

  const commitEmailInput = () => {
    const possibleEmails = _.chain(inputValue.split(emailSeparators)).compact().uniq().value()
    props.onChange(_.union(props.emails, possibleEmails))

    setInputValue('')
  }

  const onEmailTagDelete = (removingEmail: string) => {
    props.onChange(_.without(props.emails, removingEmail))
  }

  interface IPropsForTags {
    color: EMAIL_TAG_COLORS,
    icon: JSX.Element
  }

  const getPropsForTag = (email: string): IPropsForTags | undefined => {
    const { validationResponse } = props

    if (!validationResponse) {
      return
    }

    if (validationResponse.invalidEmails.includes(_.trim(email))) {
      return { color: EMAIL_TAG_COLORS.red, icon: <CloseCircleOutlined /> }
    }

    if (validationResponse.activeUserEmails.includes(_.trim(email))) {
      return { color: EMAIL_TAG_COLORS.red, icon: <WarningOutlined /> }
    }

    if (validationResponse.invitedUserEmails.includes(_.trim(email))) {
      return { color: EMAIL_TAG_COLORS.green, icon: <CheckCircleOutlined /> }
    }

    if (validationResponse.awaitingConfirmationUsersEmails.includes(_.trim(email))) {
      return { color: EMAIL_TAG_COLORS.orange, icon: <ExclamationCircleOutlined /> }
    }

    return undefined
  }

  const enrichEmailWithPropsForTags = (email: string) => {
    const propsForTag = getPropsForTag(email);

    let sortPriority = SORT_PRIORITY_FOR_EMAILS_BASED_ON_TAG_COLOR['default'];
    if(propsForTag?.color) {
      sortPriority = SORT_PRIORITY_FOR_EMAILS_BASED_ON_TAG_COLOR[propsForTag.color]
    }
    return {
      email,
      propsForTag: propsForTag,
      sortPriority
    }
  }

  return (
    <React.Fragment>
      <div
        style={{
          minHeight: '100px',
          border: '1px rgb(217, 217, 217) solid',
          padding: '8px',
          maxHeight: '50vh',
          overflow: 'scroll',
        }}
        onClick={(e) => {
          if (inputRef.current && e.target === e.currentTarget) {
            inputRef.current.focus()
          }
        }}
      >
        {props.emails
        .map(enrichEmailWithPropsForTags)
        .sort((a,b) => a.sortPriority - b.sortPriority)
        .map(({email, propsForTag}) => {
          const shortEmail = _.truncate(email, { length: 30 })

          const emailTag = (
            <Tag
              key={email}
              style={{ borderRadius: 4 }}
              closable
              onClose={() => onEmailTagDelete(email)}
              {...propsForTag}
            >
              {shortEmail}
            </Tag>
          )

          if (email !== shortEmail) {
            return (
              <Tooltip title={email} key={email}>
                {emailTag}
              </Tooltip>
            )
          }

          return emailTag
        })}
        <Input
          ref={inputRef}
          type="text"
          size="small"
          bordered={false}
          prefix={<PlusOutlined />}
          value={inputValue}
          onChange={(e) => {
            setInputValue(e.currentTarget.value)
          }}
          onBlur={commitEmailInput}
          onPressEnter={commitEmailInput}
          onKeyDown={(e) => {
            if (emailSeparators.test(e.key)) {
              commitEmailInput()
              e.preventDefault()
            }
          }}
          placeholder="Email"
          autoFocus
        />
      </div>
      <div style={{height: '10px', fontSize: '12px'}}>
        {props.validating ? <div><Spin indicator={<LoadingOutlined style={{ fontSize: 12 }} spin />}/> Validating...</div> : null}
      </div>
    </React.Fragment>
  )
}
