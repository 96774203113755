import { IPrivateServerInfo, ILicenseUpdateRequest, IApiUpdateResult } from '../types'
import * as AuthServices from './AuthServices'

export async function fetchLicense(): Promise<IPrivateServerInfo> {
  const client = AuthServices.getAuthenticatedApiConnector()
  if (!client) {
    throw new Error('Not authorized')
  }

  return client.get<IPrivateServerInfo>('license', undefined)
}

/** @internal: Only use for diagnostic active users count issue #172105. */
export async function fetchLicenseUsersInfo(): Promise<any> {
  const client = AuthServices.getAuthenticatedApiConnector()
  if (!client) {
    throw new Error('Not authorized')
  }

  return client.get<IPrivateServerInfo>('internal/license-users-info', undefined)
}

export async function updateGoogleReportEnabled(isEnabled: boolean) {
  const client = AuthServices.getAuthenticatedApiConnector()
  if (!client) {
    throw new Error('Not authorized')
  }
  const result = await client.post<IApiUpdateResult>('license/set-google-report-enabled', {
    isEnabled
  })

  return !!result.success
}

export async function updateTimeTrackingEnabled(isEnabled: boolean) {
  const client = AuthServices.getAuthenticatedApiConnector()
  if (!client) {
    throw new Error('Not authorized')
  }
  const result = await client.post<IApiUpdateResult>('license/set-timetrack-enabled', {
    isEnabled
  })

  return !!result.success
}

export async function updateTransferOwnershipEnabled(isEnabled: boolean) {
  const client = AuthServices.getAuthenticatedApiConnector()
  if (!client) {
    throw new Error('Not authorized')
  }
  const result = await client.post<IApiUpdateResult>('license/set-transfer-ownership-enabled', {
    isEnabled
  })

  return !!result.success
}

export async function updateAccountDeletionEnabled(isEnabled: boolean) {
  const client = AuthServices.getAuthenticatedApiConnector()
  if (!client) {
    throw new Error('Not authorized')
  }
  const result = await client.post<IApiUpdateResult>('license/set-account-deletion-enabled', {
    isEnabled
  })

  return !!result.success
}

export async function updateAllProjectAdminTransferEnabled(isEnabled: boolean) {
  const client = AuthServices.getAuthenticatedApiConnector()
  if (!client) {
    throw new Error('Not authorized')
  }
  const result = await client.post<IApiUpdateResult>('license/set-force-project-admin-transfer', {
    isEnabled
  })

  return !!result.success
}

export async function updateUserExportEnabled(enabled: boolean) {
  const client = AuthServices.getAuthenticatedApiConnector()
  if (!client) {
    throw new Error('Not authorized')
  }

  await client.post<IApiUpdateResult>('license/user-export', {
    enabled
  })
}

export async function updateLicensePermission(request: ILicenseUpdateRequest) {
  const client = AuthServices.getAuthenticatedApiConnector()
  if (!client) {
    throw new Error('Not authorized')
  }
  const result = await client.post<IApiUpdateResult>('license/update', request)

  return !!result.success
}
