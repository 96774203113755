import React, { useEffect } from 'react'

import { RedirectInternal } from '../routes'
import * as AuthServices from '../services/AuthServices'

export default function LogoutPage() {
  useEffect(() => {
    AuthServices.logout()
  }, [])

  return <RedirectInternal to="/login" />
}
